import { storyblokEditable } from '@storyblok/js';
import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Recipe } from '@/components/recipe';

const RecipeSlice = ({ blok }) => {
  return (
    <Recipe
      title={blok.title}
      items={
        blok.items
          ? blok.items.map((item) => {
              return {
                // TODO:
                // icon: '',
                title: item.title,
                content: render(item.content),
              };
            })
          : null
      }
      {...storyblokEditable(blok)}
    />
  );
};

export default RecipeSlice;
