import { storyblokEditable } from '@storyblok/js';
import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Button } from '@/components/button';
import { MaxWidth } from '@/components/max-width';

import { generateImageProps, isImage } from '@/helpers/image';

const Cta = ({ blok }) => {
  return (
    <div className="cta" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="cta__wrap">
          {isImage(blok.image) ? (
            <img
              className="cta__illustration"
              {...generateImageProps(blok.image, {
                sizes: '200px',
                lazy: true,
                draggable: false,
              })}
            />
          ) : null}

          {blok.title ? (
            <div className="cta__title">
              <h2>{blok.title}</h2>
            </div>
          ) : null}
          {blok.content ? (
            <div className="cta__content">
              <p>{render(blok.content)}</p>
            </div>
          ) : null}
          {blok.link[0] ? (
            <div className="cta__button">
              <Button field={blok.link[0].link} theme="black">
                {blok.link[0].text}
              </Button>
            </div>
          ) : null}
        </div>
      </MaxWidth>
    </div>
  );
};

export default Cta;
