import { getSlice } from '@/sections';
import React, { useMemo } from 'react';

import { Layout } from '@/components/layout/index.js';
import RecipeHero from '@/components/recipe-hero';

import { useData } from '@/helpers/data.js';
import { generateRecipeSnippet } from '@/helpers/snippet';
import { richTextToString } from '@/helpers/storyblok/rich-text';

export const RecipeLayout = () => {
  const { locale, layouts, story, contentTypes } = useData();

  const layout = layouts.recipe;

  const body = story.content?.body || [];

  // Snippet formatting
  const recipe = useMemo(() => {
    const preparationData = body.find(
      (slice) => slice.component === 'recipe-preparation',
    );
    const data = { ...story?.content, datePublished: story?.published_at };
    if (preparationData) {
      // Map ingredients
      data.ingredients = preparationData.ingredients.map(
        (ingredient) =>
          `${ingredient.quantity} ${richTextToString(ingredient.content)}`,
      );

      // Map Preparation
      data.preparationSteps = preparationData.preparationSteps
        .map((step) => richTextToString(step.content))
        .filter((step) => step);
    }
    return data;
  }, [story]);

  return (
    <Layout locale={locale}>
      {generateRecipeSnippet({
        recipe,
      })}

      <RecipeHero blok={story.content} />

      {(body || []).map((item, index) => {
        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice
            key={item._uid}
            index={index}
            blok={item}
            content={story.content}
          />
        ) : null;
      })}

      {(layout?.content?.body || []).map((item, index) => {
        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice
            key={item._uid}
            index={index}
            blok={item}
            content={story.content}
            contentTypes={contentTypes}
          />
        ) : null;
      })}
    </Layout>
  );
};
