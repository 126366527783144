import { findCartLineVariant } from '@/components/cart/actions';

import { convertToGa4Item } from './google-analytics';
import { isInVisualEditor } from './storyblok/is-in-visual-editor';

const pushDataLayerEvent = (event) => {
  if (typeof window !== 'undefined' && !isInVisualEditor()) {
    window.dataLayer = window.dataLayer || [];

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);

    if (process.env.NODE_ENV === 'development') {
      console.log('dataLayer.push', event);
    }
  }
};

export const triggerViewItem = (shopifyProduct) => {
  if (shopifyProduct) {
    const item = convertToGa4Item({ shopifyProduct });

    pushDataLayerEvent({
      event: 'view_item',
      ecommerce: {
        value: item.price,
        currency: item.currency,
        items: [item],
      },
    });
  }
};

export const triggerViewItemList = ({ itemListName, shopifyProducts }) => {
  pushDataLayerEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_name: itemListName,
      items: shopifyProducts.map((shopifyProduct) => {
        return convertToGa4Item({ shopifyProduct });
      }),
    },
  });
};

export const triggerSelectItem = ({ itemListName, shopifyProduct }) => {
  pushDataLayerEvent({
    event: 'select_item',
    ecommerce: {
      item_list_name: itemListName,
      items: [convertToGa4Item({ shopifyProduct })],
    },
  });
};

export const triggerViewCart = (cartData) => {
  const items = cartData.lines.edges.map((line) => {
    const shopifyProduct = line.node.merchandise.product;
    const variant = findCartLineVariant(line);

    const item = convertToGa4Item({
      shopifyProduct,
      variant,
    });

    return {
      ...item,
      quantity: line.node.quantity,
    };
  });

  pushDataLayerEvent({
    event: 'view_cart',
    ecommerce: {
      value: parseFloat(cartData.cost.totalAmount.amount),
      currency: cartData.cost.totalAmount.currencyCode,
      items,
    },
  });
};

export const triggerCartVariation = ({
  event,
  shopifyProduct,
  variant,
  quantity,
}) => {
  const item = convertToGa4Item({
    shopifyProduct,
    variant,
    quantity,
  });

  const value = item.price * quantity;

  pushDataLayerEvent({
    event,
    ecommerce: {
      value,
      currency: item.currency,
      items: [item],
    },
  });
};

export const triggerAddToCart = ({ shopifyProduct, variant, quantity }) => {
  triggerCartVariation({
    event: 'add_to_cart',
    shopifyProduct,
    variant,
    quantity,
  });
};

export const triggerRemoveFromCart = ({
  shopifyProduct,
  variant,
  quantity,
}) => {
  triggerCartVariation({
    event: 'remove_from_cart',
    shopifyProduct,
    variant,
    quantity,
  });
};
