import { PAGE_TYPE } from 'helpers/storyblok/index.js';

import { BlogPostLayout } from './blog-post.js';
import { PageLayout } from './page.js';
import { ProductLayout } from './product.js';
import { RecipeLayout } from './recipe.js';

const LAYOUT = {
  [PAGE_TYPE.PAGE]: PageLayout,
  [PAGE_TYPE.RECIPE]: RecipeLayout,
  [PAGE_TYPE.BLOG_POST]: BlogPostLayout,
};

const DEFAULT_LAYOUT = LAYOUT.page;

export const getLayout = (data) => {
  if (data.product) {
    return ProductLayout;
  }

  return LAYOUT[data?.story?.content?.component] || DEFAULT_LAYOUT;
};
