import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { CartModal } from '@/components/cart/modal';
import { OpenCart } from '@/components/cart/open-cart';

import { CONFIG } from '../../config';

import { Link } from '../link';
import { MaxWidth } from '../max-width';
import { Menu } from '../menu';

const HeaderNavItem = ({ text, link }) => {
  return (
    <>
      <li key={text} className="header__nav__item">
        <Link link={link}>
          <span>{text}</span>
        </Link>
      </li>
    </>
  );
};

export const Header = ({ blok, locale }) => {
  const [closing, setClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const [touchDown, setTouchDown] = useState(false);
  const headerRef = useRef(null);

  const toggleMenu = () => {
    if (!open) {
      setClosing(false);
      setOpen(true);
      document.body.style.overflow = 'hidden';
    } else {
      setClosing(true);
      setOpen(false);
      document.body.style.overflow = 'visible';
    }
  };

  const leftPart = blok.links.slice(0, Math.round(blok.links.length / 2));

  const rightPart = blok.links.slice(
    Math.round(blok.links.length / 2),
    blok.links.length,
  );

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window && document) {
      const handleScroll = () => {
        setScrolled(
          document.scrollingElement && document.scrollingElement.scrollTop > 0
            ? true
            : false,
        );
      };

      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <header
        className={classnames('header', {
          'header--scrolled': scrolled,
        })}
        ref={headerRef}
      >
        <div className="header__sup-header">
          <span>{blok.banner}</span>
        </div>

        <MaxWidth>
          <div className="header__grid">
            <div />

            <div className="header__part header__part--left">
              <ul className="header__nav">
                {leftPart.map((item) => (
                  <HeaderNavItem key={item.text} {...item} />
                ))}
              </ul>
            </div>

            <Link className="header__logo" href={locale.pathPrefix}>
              <img
                className="header__logo__light"
                src="/logo-light.svg"
                width="60"
                height="60"
                alt={CONFIG.title}
                draggable={false}
              />
              <img
                className="header__logo__dark"
                src="/logo-dark.svg"
                width="60"
                height="60"
                alt={CONFIG.title}
                draggable={false}
              />
              <img
                className="header__logo__mobile__scrolled"
                src="/logo-mobile-scrolled.svg"
                alt={CONFIG.title}
                draggable={false}
              />
            </Link>

            <div className="header__part header__part--right">
              <ul className="header__nav">
                {rightPart.map((item) => (
                  <HeaderNavItem key={item.text} {...item} />
                ))}
              </ul>
            </div>
            <OpenCart />

            <button
              className={classnames('header__burger', {
                'header__burger--touch-down': touchDown,
              })}
              onClick={toggleMenu}
              onTouchStart={() => setTouchDown(true)}
              onTouchMove={() => setTouchDown(false)}
              onTouchEnd={() => setTouchDown(false)}
            >
              <div className="header__burger__icon" />
              <span className="visually-hidden">Open menu</span>
            </button>
          </div>
        </MaxWidth>
      </header>

      <Menu
        blok={blok}
        open={open}
        closing={closing}
        toggleMenu={toggleMenu}
        locale={locale}
      />

      <CartModal />
    </>
  );
};
