import { format, isAfter, isSameDay, isValid } from 'date-fns';
import { fr as frLocale } from 'date-fns/locale';

import { Summary } from '@/components/summary';

export const BlogBodyAside = ({ story }) => {
  const parsedPublishDate = new Date(story.content.publishDate);
  const parsedModifiedDate = new Date(story?.published_at);
  const modifiedDate =
    isValid(parsedPublishDate) &&
    isValid(parsedModifiedDate) &&
    isAfter(parsedModifiedDate, parsedPublishDate) &&
    !isSameDay(parsedPublishDate, parsedModifiedDate)
      ? parsedModifiedDate
      : null;

  return (
    <aside className="blog-body__aside">
      <div className="blog-body__aside__sticky">
        {modifiedDate ? (
          <p className="blog-body__aside__title">
            Mis à jour le{' '}
            {format(modifiedDate, 'd MMMM yyyy', {
              locale: frLocale,
            })}
          </p>
        ) : null}

        <Summary body={story?.content?.content} />
      </div>
    </aside>
  );
};
