import { useQuery } from '@apollo/client';

import { GET_PRODUCT_QUERY } from '@/helpers/apollo/queries/product';

export const useProductData = (handle) => {
  const { loading, error, data } = useQuery(GET_PRODUCT_QUERY, {
    variables: { handle },
    skip: !handle, // Only execute the query if a handle is provided
  });

  return {
    loading,
    error,
    productData: data?.product,
  };
};
