'use client';

import { useState } from 'react';

import { useCart } from '@/helpers/shopify/cart';

import { useCartActions } from '../cart/actions';
import EditQuantity from '../edit-quantity';

export const EditItemQuantityButton = ({ line, enableInput = false }) => {
  const [cart] = useCart();
  const [loading, setLoading] = useState(false);
  const { updateItemQuantity } = useCartActions();

  const handleQuantityChange = async (quantity) => {
    setLoading(true);

    await updateItemQuantity({
      line,
      quantity,
    });

    setLoading(false);
  };

  return (
    <EditQuantity
      loading={loading || cart.networkStatus === 4}
      productQuantity={line.node.quantity}
      setProductQuantity={handleQuantityChange}
      enableInput={enableInput}
    />
  );
};
