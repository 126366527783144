import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { Slider } from '@/components/slider';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const SliderSection = ({ blok }) => {
  return (
    <Slider
      slides={blok.slides.map((item) => {
        return {
          title: item.title,
          content: render(item.content),
          backgroundImage: generateImageProps(item.backgroundImage, {
            sizes: '(min-width: 769px) 700px, 75vw',
            draggable: 'false',
          }),
        };
      })}
      {...storyblokEditable(blok)}
    />
  );
};

export default SliderSection;
