import { storyblokEditable } from '@storyblok/js';
import { useEffect, useMemo } from 'react';

import { MaxWidth } from '@/components/max-width';
import { ProductCard } from '@/components/product-card';

import { triggerViewItemList } from '@/helpers/gtm';

const ProductDiscover = ({ blok, product, products }) => {
  const otherProducts = useMemo(() => {
    const filteredProducts = products.filter((item) => {
      return item.id !== product.id;
    });

    return process.env.NODE_ENV === 'development'
      ? filteredProducts
      : filteredProducts?.filter((product) => {
          return !product.tags.includes('development');
        });
  }, [products, product]);

  useEffect(() => {
    if (otherProducts && otherProducts.length > 0) {
      triggerViewItemList({
        itemListName: blok.title,
        shopifyProducts: otherProducts,
      });
    }
  }, [otherProducts, blok.title]);

  return (
    <div className="product-discover" {...storyblokEditable(blok)}>
      <MaxWidth>
        <hr />

        <h2>{blok.title}</h2>

        <div className="product-discover__content-container">
          <div className="product-discover__content">
            {otherProducts.slice(0, 3).map((item) => {
              return <ProductCard key={item.id} product={item} />;
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ProductDiscover;
