import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { BlogCard } from '@/components/blog-card';
import { MaxWidth } from '@/components/max-width';

const BlogIndex = ({ blok, contentTypes }) => {
  return (
    <div className="blog-index" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="blog-index__list">
          {contentTypes.blogPosts.map((story) => (
            <BlogCard key={story.full_slug} story={story} />
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default BlogIndex;
