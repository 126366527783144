import { storyblokEditable } from '@storyblok/js';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { RateStarIcon, RateStarIconFilled } from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';
import { replaceValuesInRichText } from '@/helpers/string';

const MAX_RATING = 5;

const ProductRatingProgress = ({ rate, value, maxValue = MAX_RATING }) => {
  return (
    <div
      className="product-rating-progress"
      style={{
        '--progress-width': `${(value * 100) / maxValue}%`,
      }}
    >
      <p className="product-rating-progress__rate">{rate}</p>
      <div className="product-rating-progress__progress-bar">
        <div className="product-rating-progress__progress-bar__fill" />
      </div>
    </div>
  );
};

const ProductRatingStars = ({ rating, maxRating = MAX_RATING }) => {
  return (
    <div className="product-rating-stars">
      {new Array(rating).fill(1).map((rate, index) => {
        return <RateStarIconFilled key={index} />;
      })}
      {new Array(maxRating - rating).fill(1).map((rate, index) => {
        return <RateStarIcon key={index} />;
      })}
    </div>
  );
};

const ProductRating = ({ blok, id = '', pushRef = null }) => {
  const rating = useMemo(() => {
    const initialValue = 0;
    return (
      blok.reviews.reduce((accumulator, item) => {
        return accumulator + parseInt(item.rate);
      }, initialValue) / blok.reviews.length
    );
  }, [blok.reviews]);

  return (
    <div
      className="product-rating"
      id={id}
      ref={(el) => pushRef?.(el)}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <h2 className="product-rating__left__title">{blok.title}</h2>

        <div className="product-rating__content">
          <div className="product-rating__left">
            <div className="product-rating__left__summary">
              <p className="product-rating__left__summary__rating">
                {parseFloat(rating).toFixed(1)}
              </p>
              <div className="product-rating__left__summary__stars">
                <ProductRatingStars rating={rating} />
                <div>
                  {render(
                    replaceValuesInRichText(blok.ratingLabel, [
                      {
                        key: '{RATING}',
                        value: rating,
                      },
                      {
                        key: '{REVIEWS}',
                        value: blok.reviews.length,
                      },
                    ]),
                  )}
                </div>
              </div>
            </div>
            <div className="product-rating__left__description">
              {render(
                replaceValuesInRichText(blok.description, [
                  {
                    key: '{RATING}',
                    value: rating,
                  },
                  {
                    key: '{REVIEWS}',
                    value: blok.reviews.length,
                  },
                ]),
              )}
            </div>
            <div className="product-rating__left__progress">
              {new Array(MAX_RATING).fill(1).map((item, index) => {
                return (
                  <ProductRatingProgress
                    key={index}
                    rate={MAX_RATING - index}
                    value={
                      blok.reviews.filter((review) => {
                        return parseInt(review.rate) === MAX_RATING - index;
                      }).length
                    }
                    maxValue={blok.reviews.length}
                  />
                );
              })}
            </div>
          </div>
          <div className="product-rating__reviews">
            {blok.reviews.slice(0, 2).map((review, index) => {
              return (
                <div key={index} className="product-rating__review">
                  <div className="product-rating__review__top">
                    <h3>{review.name}</h3>
                    <ProductRatingStars rating={parseInt(review.rate)} />
                  </div>
                  <div className="product-rating__review__content">
                    {render(review.content)}
                  </div>
                  <p className="product-rating__review__date">
                    {format(new Date(review.date), 'MM/dd/yyyy')}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ProductRating;
