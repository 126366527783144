import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import React from 'react';

import { MaxWidth } from '@/components/max-width';
import { Title } from '@/components/title';

const TitleSection = ({ blok, index }) => {
  const isFirst = index === 0;
  return (
    <div
      className={classNames('subject', {
        'subject--is-first': isFirst,
      })}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <Title
          items={{
            title: blok.title,
            description: blok.description,
          }}
          params={{
            isFirst: index === 0,
          }}
        />
      </MaxWidth>
    </div>
  );
};

export default TitleSection;
