import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import isMobile from 'is-mobile';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';

const Video = ({ blok }) => {
  const isMobileState = isMobile();

  const hasVideo = Object.keys(blok.videoUrl).length > 0;

  const [mountEmbed, setMountEmbed] = useState(false);

  const [isStarted, setIsStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(!isMobileState);

  const player = useRef(null);

  useEffect(() => {
    let handleEvent;

    const removeEventListeners = () => {
      document.removeEventListener('scroll', handleEvent, {
        capture: false,
      });

      document.removeEventListener('mousemove', handleEvent, {
        capture: false,
      });
    };

    handleEvent = () => {
      setMountEmbed(true);

      removeEventListeners();
    };

    document.addEventListener('scroll', handleEvent, {
      capture: false,
      passive: true,
    });

    document.addEventListener('mousemove', handleEvent, {
      capture: false,
      passive: true,
    });

    return removeEventListeners;
  }, []);

  const handlePlayPause = () => {
    if (player.current) {
      setIsPlaying((current) => !current);
    }
  };

  const handleStart = () => {
    if (player.current) {
      if (isStarted) {
        handlePlayPause();
      } else {
        player.current.seekTo(0);

        setIsMuted(false);
        setIsPlaying(true);

        setIsStarted(true);
      }
    }
  };

  const youtubeIdMatch =
    blok.videoUrl &&
    blok.videoUrl.embed_url &&
    blok.videoUrl.embed_url.match(/[?&]v=([^&]+)/);

  const youtubeId = youtubeIdMatch ? youtubeIdMatch[1] : null;

  return (
    <div className="video" {...storyblokEditable(blok)}>
      <div
        className={classNames('video__player', {
          'video__player--hasnt-video': !hasVideo,
        })}
      >
        <div className="video__player__cover">
          <img
            {...generateImageProps(blok.backgroundImage, {
              sizes: '(min-width: 769px) 500px, 50vw',
              draggable: 'false',
            })}
          />
        </div>

        <div className="video__player__wrap">
          {hasVideo && mountEmbed && (
            <ReactPlayer
              ref={player}
              url={blok.videoUrl}
              playing={isPlaying}
              loop
              controls={isMobileState}
              muted={isMuted}
              width="100%"
              height={isMobileState ? '100%' : '202%'}
              config={{
                youtube: {
                  preload: true,
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com',
                  },
                  playerVars: {
                    playsinline: isMobileState ? 0 : 1,
                    modestbranding: 1,
                    showinfo: 0,
                    loop: 1,
                    playlist: youtubeId,
                  },
                },
              }}
            />
          )}

          <div className="video__control">
            <button
              className="video__control__play-pause"
              onClick={handlePlayPause}
              aria-label={isPlaying ? 'Pause' : 'Play'}
              tabIndex={isStarted ? null : -1}
            />
          </div>
        </div>
      </div>

      <div className="video__player__gradient" />

      {hasVideo && (
        <div
          className="video__wrapper"
          onClick={isStarted && isPlaying ? handlePlayPause : null}
        >
          <MaxWidth>
            <div className="video__wrap">
              {/*  <div className="video__content">
              <div className="video__title">
                {slice.primary.title ? (
                  <h2>{RichText.asText(slice.primary.title)}</h2>
                ) : null}
              </div>

              <div className="video__description">
                {slice.primary.description ? (
                  <p>{RichText.asText(slice.primary.description)}</p>
                ) : null}
              </div>
            </div>
 */}
              <div className="video__play">
                <button
                  onClick={isStarted ? handlePlayPause : handleStart}
                  tabIndex={isStarted ? -1 : null}
                >
                  <div className="video__play__img">
                    <img
                      src="/icons/play.png"
                      srcSet="/icons/play.svg"
                      alt=""
                      draggable={false}
                    />
                  </div>

                  {blok.playButton ? <span>{blok.playButton}</span> : null}
                </button>
              </div>
            </div>
          </MaxWidth>
        </div>
      )}
    </div>
  );
};

export default Video;
