import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';
import { ProductCardByHandle } from '@/components/product-card';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const Highlights = ({ blok }) => {
  return (
    <div className="highlights" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="highlights__container">
          {blok.products.map((item, index) => (
            <ProductCardByHandle handle={item.handle} key={item.handle} />
          ))}

          <Link link={blok.cardLink} className="highlights__card">
            <div className="highlights__card-gradient" />
            <div className="highlights__card-background">
              <img
                {...generateImageProps(blok.cardImage, {
                  sizes: '(min-width: 769px) 600px, 25vw',
                  draggable: 'false',
                })}
              />
            </div>

            <div className="highlights__card-content">
              <div className="highlights__card-title">
                {blok.cardTitle ? <h3>{blok.cardTitle}</h3> : null}
              </div>

              <div className="highlights__card-description">
                {blok.cardDescription ? render(blok.cardDescription) : null}
              </div>
            </div>
          </Link>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Highlights;
