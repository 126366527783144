import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import Spinner from 'react-svg-spinner';

import { Button } from '@/components/button';
import { Link } from '@/components/link';

import { useData } from '@/helpers/data';
import { triggerSelectItem } from '@/helpers/gtm';
import { useProductData } from '@/helpers/hooks/use-product-data';
import { useVariantSelection } from '@/helpers/hooks/use-variant-selection';
import { formatCurrency } from '@/helpers/text';

import { CONFIG } from '@/config';

import { useCartActions } from '../cart/actions';

export const ProductCard = ({ product, itemListName }) => {
  const [loadingAddToCart, setLoadingAddToCart] = useState(false);

  const { layouts } = useData();

  const [id] = useState(`id_${nanoid()}`);

  const { selectedVariant, handleOptionChange } = useVariantSelection(product);
  const { addItem } = useCartActions();

  const renderableOptions = product?.options.filter((option) => {
    return option.name !== 'Title';
  });
  const handleProductClick = () => {
    triggerSelectItem({
      itemListName,
      shopifyProduct: product,
    });
  };

  const handleAddToCartClick = async () => {
    if (!selectedVariant?.id) {
      return alert('Please select an option');
    }

    setLoadingAddToCart(true);

    await addItem({
      shopifyProduct: product,
      variant: selectedVariant,
      quantity: 1,
    });

    setLoadingAddToCart(false);
  };
  return (
    <div className="product-item">
      <div className="product-item__image-container">
        {product?.featuredImage &&
          (product.variants.edges || product.variants).map((variant, index) => {
            variant = variant.node || variant;
            return (
              <img
                className={classNames('product-item__image', {
                  'product-item__image--active':
                    (selectedVariant && variant.id === selectedVariant.id) ||
                    (index === 0 && !selectedVariant),
                })}
                key={variant.id}
                src={variant.image.url}
                alt={variant.image.altText || 'Product Image'}
              />
            );
          })}
      </div>

      <div className="product-item__title">
        <h3>
          <Link
            href={`/${CONFIG.productsSlug}/${product?.handle}`}
            onClick={handleProductClick}
          >
            {product?.title}
          </Link>
        </h3>
      </div>

      {(renderableOptions || []).map((option) => {
        return (
          <div className="product-item__options" key={option.id}>
            {option.optionValues.map((value, index) => {
              return (
                <div key={value.name} className="product-item__options__item">
                  <input
                    type="radio"
                    id={`${id}-${option.id}-${index}`}
                    name={`${id}_${option.id}`}
                    value={value.name}
                    checked={
                      selectedVariant?.selectedOptions.some(
                        (selectedOption) => {
                          return (
                            selectedOption.name === option.name &&
                            selectedOption.value === value.name
                          );
                        },
                      ) || false
                    }
                    onChange={handleOptionChange}
                  />

                  <label htmlFor={`${id}-${option.id}-${index}`}>
                    {value.name}
                  </label>
                </div>
              );
            })}
          </div>
        );
      })}

      <div className="product-item__price">
        {selectedVariant && formatCurrency(selectedVariant.price.amount)}
      </div>

      <div className="product-item__add-to-cart">
        <Button width="full" theme="black" onClick={handleAddToCartClick}>
          {loadingAddToCart ? (
            <Spinner
              color="white"
              speed="fast"
              size="22"
              gap={4}
              thickness={4}
            />
          ) : (
            layouts.product?.content?.addToCartLabel
          )}
        </Button>
      </div>
    </div>
  );
};

export const ProductCardByHandle = ({ handle }) => {
  const { loading, error, productData } = useProductData(handle);

  if (loading) {
    return (
      <div className="product-item product-item--loading">
        <Spinner color="black" speed="fast" size="16" gap={4} thickness={4} />
      </div>
    );
  }

  if (error) {
    return null;
  }

  return <ProductCard product={productData} />;
};
