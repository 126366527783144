import diacritics from 'diacritics';
import React, { useRef } from 'react';
import { StickyContainer } from 'react-sticky';

import { getSlice } from '../sections/index.js';

import { Layout } from '@/components/layout/index.js';
import { ProductBreadCrumb } from '@/components/product-breadcrumb/index.js';
import { ProductHero } from '@/components/product-hero/index.js';
import { Recipe } from '@/components/recipe/index.js';
import { Slider } from '@/components/slider/index.js';

import { useData } from '@/helpers/data.js';
import { shopifyRichTextRender } from '@/helpers/shopify';
import { parseMetafield } from '@/helpers/shopify/metafields.js';
import { generateProductSnippet } from '@/helpers/snippet.js';

export const ProductLayout = () => {
  const { locale, product, products, layouts, contentTypes } = useData();

  const elementsRef = useRef([]);

  const layout = layouts.product;

  const slider = parseMetafield(product.slider);
  const hasSlider =
    slider && slider?.length > 0 && slider.find((slide) => slide.content);
  const tastingAdvices = parseMetafield(product.tasting_advices);
  // TODO:
  // const reviews = parseMetafield(product.reviews);

  const breadCrumb = [];

  if (hasSlider) {
    breadCrumb.push({
      title: slider[0].title,
      href: `#product-${diacritics.remove(slider[0].title).toLowerCase()}`,
    });
  }

  if (tastingAdvices) {
    breadCrumb.push({
      title: layout?.content?.tastingAdvicesTitle,
      href: `#product-${diacritics
        .remove(layout?.content?.tastingAdvicesTitle)
        .toLowerCase()}`,
    });
  }

  breadCrumb.push(
    ...(layout?.content?.body || [])
      .filter((slice) => {
        if (
          slice.component === 'product-recipes' &&
          contentTypes?.filteredBy?.recipes?.recipes &&
          contentTypes?.filteredBy?.recipes?.recipes?.length <= 0
        ) {
          return false;
        }
        return slice.breadcrumbTitle && slice.breadcrumbAnchor;
      })
      .map((slice) => ({
        title: slice.breadcrumbTitle,
        href: `#product-${diacritics
          .remove(slice.breadcrumbAnchor)
          .toLowerCase()}`,
      })),
  );

  const pushRef = (el) => {
    if (el && el.id && !elementsRef.current.includes(el))
      elementsRef.current.push(el);
  };

  return (
    <Layout locale={locale}>
      {generateProductSnippet({
        product,
      })}

      <ProductHero product={product} breadcrumb={layout?.content?.breadcrumb} />

      <StickyContainer>
        {breadCrumb.length > 0 ? (
          <ProductBreadCrumb links={breadCrumb} elementsRef={elementsRef} />
        ) : null}

        {hasSlider ? (
          <Slider
            id={`product-${diacritics.remove(slider[0].title).toLowerCase()}`}
            slides={slider
              .filter((slide) => slide.content)
              .map((item) => {
                return {
                  title: item.title,
                  dangerousHtmlContent: shopifyRichTextRender(item.content),
                  backgroundImage: item.background_image?.image
                    ? {
                        src: item.background_image?.image.url,
                        width: item.background_image?.image.width,
                        height: item.background_image?.image.height,
                        alt: item.background_image?.image.altText,
                      }
                    : null,
                };
              })}
            pushRef={pushRef}
          />
        ) : null}

        {tastingAdvices?.length > 0 ? (
          <Recipe
            id={`product-${diacritics
              .remove(layout?.content?.tastingAdvicesTitle)
              .toLowerCase()}`}
            title={layout?.content?.tastingAdvicesTitle}
            items={tastingAdvices.map((item) => {
              return {
                icon: item.icon,
                title: item.titre,
                content: item.description,
              };
            })}
            pushRef={pushRef}
          />
        ) : null}

        {(layout?.content?.body || []).map((item, index) => {
          const Slice = getSlice(item.component);

          return Slice ? (
            <Slice
              id={
                item.breadcrumbAnchor
                  ? `product-${diacritics
                      .remove(item.breadcrumbAnchor)
                      .toLowerCase()}`
                  : ''
              }
              key={item._uid}
              index={index}
              blok={item}
              product={product}
              products={products}
              layout={layout}
              pushRef={pushRef}
              contentTypes={contentTypes}
            />
          ) : null;
        })}
      </StickyContainer>
    </Layout>
  );
};
