import { format, isValid } from 'date-fns';
import { fr as frLocale } from 'date-fns/locale';

import { MaxWidth } from '@/components/max-width';

import { hasText, render } from '@/helpers/storyblok/rich-text';

export const BlogHeader = ({ story }) => {
  const parsedPublishDate = new Date(story.content.publishDate);
  const publishDate = isValid(parsedPublishDate) ? parsedPublishDate : null;

  return (
    <header className="blog-header">
      <MaxWidth>
        <div className="blog-header__header">
          <div className="blog-header__date">
            {publishDate
              ? format(publishDate, 'd MMMM yyyy', {
                  locale: frLocale,
                })
              : null}
          </div>
        </div>

        <h1 className="blog-header__title">{story.content.title}</h1>

        {hasText(story.content.introduction) ? (
          <div className="blog-header__introduction">
            {render(story.content.introduction)}
          </div>
        ) : null}
      </MaxWidth>
    </header>
  );
};
