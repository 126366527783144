import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { Button } from '@/components/button';
import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const Hero = ({ blok, index }) => {
  const isFirst = index === 0;

  return (
    <div className="hero" {...storyblokEditable(blok)}>
      <div className="hero__background">
        <img
          {...generateImageProps(blok.image, {
            sizes: '(min-width: 769px) 100vw, 150vw',
            draggable: 'false',
          })}
        />
        <div className="hero__background__gradient"></div>
        <div className="hero__background__gradient__mobile"></div>
      </div>
      <MaxWidth>
        <div className="hero__container">
          {blok.title ? (
            <div className="hero__title">
              {isFirst ? (
                <h1>{render(blok.title)}</h1>
              ) : (
                <h2>{render(blok.title)}</h2>
              )}
            </div>
          ) : null}

          <div className="hero__description">
            {blok.description ? render(blok.description) : null}
          </div>

          <div className="hero__button">
            {blok.buttonText ? (
              <Button
                rightIcon="right-arrow"
                field={blok.buttonLink}
                theme="white"
              >
                {blok.buttonText}
              </Button>
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default Hero;
