import { format, isValid } from 'date-fns';
import { fr as frLocale } from 'date-fns/locale';

import { Image } from '@/components/image';
import { Link } from '@/components/link';

import { generateImageProps } from '@/helpers/image';
import { hasText, render } from '@/helpers/storyblok/rich-text';

import './index.css';

export const BlogCard = ({ as, story, ...rest }) => {
  const El = as || 'div';

  const parsedPublishDate = new Date(story.content.publishDate);
  const publishDate = isValid(parsedPublishDate) ? parsedPublishDate : null;

  return (
    <El {...rest} className="blog-card">
      <div className="blog-card__image">
        <img
          {...generateImageProps(story.content.image, {
            sizes: '(min-width: 1025px) 360px, (min-width: 769px) 50vw, 100vw',
            draggable: 'false',
          })}
        />
      </div>

      <div className="blog-card__footer">
        <Link page={story} className="blog-card__title">
          {story.content.title}
        </Link>

        {hasText(story.content.introduction) ? (
          <div className="blog-card__introduction">
            {render(story.content.introduction)}
          </div>
        ) : null}

        <div className="blog-card__date">
          {publishDate
            ? format(publishDate, 'd MMMM yyyy', {
                locale: frLocale,
              })
            : null}
        </div>
      </div>
    </El>
  );
};
