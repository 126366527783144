export const tryToParseJson = (value) => {
  try {
    const parsed = JSON.parse(value);

    return parsed;
  } catch (error) {}

  return value;
};

const formatMetaObject = (metaObject) => {
  return (metaObject?.fields || []).reduce((acc, field) => {
    return {
      ...acc,
      [field.key]:
        field.value === field?.reference?.id
          ? field.reference
          : tryToParseJson(field.value),
    };
  }, {});
};

/**
 * Replace on richtext
 * @param {Array} metafield
 */
export const parseMetafield = (metafield) => {
  if (metafield) {
    const parsed = tryToParseJson(metafield.value);

    if (Array.isArray(parsed) && Array.isArray(metafield.references?.edges)) {
      return parsed.map((item) => {
        const foundReference = metafield.references.edges.find((reference) => {
          return reference?.node?.id === item;
        })?.node;

        if (foundReference) {
          return formatMetaObject(foundReference);
        }

        return item;
      });
    }

    return parsed;
  }

  return null;
};
