import { storyblokEditable } from '@storyblok/js';
import classnames from 'classnames';
import React from 'react';

import { ICONS } from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';

import HistoryStepItems from './items';

const HistoryStep = ({ blok }) => {
  return (
    <div className="history-step" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="history-step__container__content">
          <div className="history-step__container__content__items">
            {blok.history_step_items ? (
              <HistoryStepItems items={blok.history_step_items} />
            ) : null}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default HistoryStep;
