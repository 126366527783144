import { useMutation } from '@apollo/client';

import {
  addToCartMutation,
  createCartMutation,
  editCartItemsMutation,
  removeFromCartMutation,
} from '@/helpers/apollo/mutations/cart';
import { GET_CART_QUERY } from '@/helpers/apollo/queries/cart';

export const useCartOperations = () => {
  const [
    addToCart,
    { data: addToCartData, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(addToCartMutation, {
    refetchQueries: [GET_CART_QUERY],
  });

  const [
    createCart,
    {
      data: createCartData,
      loading: createCartLoading,
      error: createCartError,
    },
  ] = useMutation(createCartMutation);

  const [
    editCartItems,
    {
      data: editCartItemsData,
      loading: editCartItemsLoading,
      error: editCartItemsError,
    },
  ] = useMutation(editCartItemsMutation, {
    refetchQueries: [GET_CART_QUERY],
  });

  const [
    removeFromCart,
    {
      data: removeFromCartData,
      loading: removeFromCartLoading,
      error: removeFromCartError,
    },
  ] = useMutation(removeFromCartMutation);

  return {
    addToCart,
    createCart,
    editCartItems,
    removeFromCart,
    loading:
      addToCartLoading ||
      createCartLoading ||
      editCartItemsLoading ||
      removeFromCartLoading,
    error:
      addToCartError ||
      createCartError ||
      editCartItemsError ||
      removeFromCartError,
    data: {
      addToCartData,
      createCartData,
      editCartItemsData,
      removeFromCartData,
    },
  };
};
