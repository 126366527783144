import { RightChevron } from '@/components/icons';
import { Link } from '@/components/link';

export const ProductHeroBreadCrumb = ({ breadcrumb, title }) => {
  return (
    <ol
      className="product-hero-breadcrumb"
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {breadcrumb.map((item, key) => {
        return (
          <li
            className="product-hero-breadcrumb__item"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
            key={item._uid}
          >
            <Link link={item.link} itemProp="item">
              <span itemProp="name">{item.text}</span>
            </Link>
            <span
              className="product-hero-breadcrumb__item__icon"
              itemProp="position"
              content={key + 1}
            >
              <RightChevron />
            </span>
          </li>
        );
      })}
      <li
        className="product-hero-breadcrumb__item"
        itemProp="itemListElement"
        itemScope
        itemType="http://schema.org/ListItem"
      >
        <span itemProp="name">{title}</span>
        <span itemProp="position" content={breadcrumb.length + 1} />
      </li>
    </ol>
  );
};
