import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import React from 'react';

import { ICONS } from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';

const RecipeHero = ({ blok }) => {
  return (
    <div className="recipe-hero" {...storyblokEditable(blok)}>
      <MaxWidth>
        <h1 className="recipe-hero__title">{blok.title}</h1>
        <div className="recipe-hero__content">
          <img
            className="recipe-hero__content__background"
            {...generateImageProps(blok.image, {
              sizes: '(min-width: 769px) 800px, 75vw',
              draggable: 'false',
            })}
          />
          <div className="recipe-hero__content__tags">
            <div className="recipe-hero__content__tag">
              <img
                className="recipe-hero__content__tag__image"
                src={ICONS.clock}
                alt=""
              />
              <p className="recipe-hero__content__tag__content">{blok.time}</p>
            </div>
            <div className="recipe-hero__content__tag recipe-hero__content__tag--hide-mobile">
              <img
                className="recipe-hero__content__tag__image"
                src={ICONS.person}
                alt=""
              />
              <p className="recipe-hero__content__tag__content">
                {blok.persons}
              </p>
            </div>
            <div className="recipe-hero__content__tag">
              <img
                className="recipe-hero__content__tag__image"
                src={ICONS['ice-cream']}
                alt=""
              />
              <p className="recipe-hero__content__tag__content">{blok.taste}</p>
            </div>
            <div className="recipe-hero__content__tag">
              <img
                className="recipe-hero__content__tag__image"
                src={ICONS.hat}
                alt=""
              />
              <p className="recipe-hero__content__tag__content">
                {blok.difficulty}
              </p>
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default RecipeHero;
