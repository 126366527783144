import { storyblokEditable } from '@storyblok/js';
import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';

const HistoryHeaderPic = ({ blok }) => {
  return (
    <div className="history-header-pic" {...storyblokEditable(blok)}>
      <img
        {...generateImageProps(blok.image, {
          sizes: '(min-width: 769px) 600px, 50vw',
          draggable: 'false',
        })}
      />
    </div>
  );
};

export default HistoryHeaderPic;
