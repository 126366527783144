import classnames from 'classnames';
import React, { useState } from 'react';

import { LOCALES } from '../../config';

import { Link } from '../link';

export const Menu = ({ blok, open, closing, toggleMenu, locale }) => {
  const [touchDown, setTouchDown] = useState(false);

  const alternateLocales = LOCALES.filter((alternateLocale) => {
    return locale !== alternateLocale;
  });

  return (
    <nav
      className={classnames('menu', {
        'menu--open': open,
        'menu--animate-close': closing,
      })}
    >
      <div className="menu__header">
        <h3 className="menu__header__title">Menu</h3>

        <button
          className={classnames('menu__burger', {
            'menu__burger--touch-down': touchDown,
          })}
          onClick={toggleMenu}
          onTouchStart={() => setTouchDown(true)}
          onTouchMove={() => setTouchDown(false)}
          onTouchEnd={() => setTouchDown(false)}
        >
          <div />
        </button>
      </div>

      <div className="menu__lists">
        <ul>
          {blok.links.map((item, index) => {
            return (
              <li key={index} onClick={toggleMenu}>
                <Link link={item.link}>{item.text}</Link>
              </li>
            );
          })}
        </ul>

        {/* {alternateLocales.length > 0 ? (
          <ul className="menu__locales">
            {alternateLocales.map((alternateLocale) => {
              const alternatePageSlug = locale.alternate_languages.find(
                (item) => item.lang === alternateLocale.locale
              );

              const href = alternatePageSlug
                ? alternatePageSlug.slug
                : alternateLocale.pathPrefix;

              return (
                <li
                  key={alternateLocale.locale}
                  className={classnames(
                    'lang-switcher__item footer_lang-switcher__item',
                    {
                      'lang-switcher__item--current':
                        locale.current.locale === alternateLocale.locale,
                    }
                  )}
                >
                  <Link
                    hrefLang={alternateLocale.shortCode}
                    href={
                      alternateLocale.locale === locale.current?.locale
                        ? locale.slug
                        : href
                    }
                    tabIndex={-1}
                    onClick={toggleMenu}
                  >
                    {alternateLocale.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null} */}
      </div>
    </nav>
  );
};
