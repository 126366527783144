import AllProducts from './all-products';
import AllRecipes from './all-recipes';
import BlogIndex from './blog-index';
import Contact from './contact';
import Cta from './cta';
import Hero from './hero';
import Highlights from './highlights';
import HistoryHeaderPic from './history-header_pic';
import HistoryInformation from './history-information';
import HistoryStep from './history-step';
import ImageText from './image-text';
import InfoCards from './info-cards';
import NotFound from './not-found';
import ProductDiscover from './product-discover';
import ProductRating from './product-rating';
import ProductRecipes from './product-recipes';
import Promotion from './promotion';
import Recipe from './recipe';
import RecipeDiscover from './recipe-discover';
import RecipePreparation from './recipe-prepation';
import SliderSection from './slider-section';
import StoryPreview from './story-preview';
import TextSection from './text-section';
import TitleSection from './title-section';
import Tracking from './tracking';
import VideoSection from './video-section';

const SLICE = {
  'all-products': AllProducts,
  'all-recipes': AllRecipes,
  BlogIndex: BlogIndex,
  contact: Contact,
  cta: Cta,
  hero: Hero,
  highlights: Highlights,
  'history-header_pic': HistoryHeaderPic,
  'history-information': HistoryInformation,
  'history-step': HistoryStep,
  'image-text': ImageText,
  'info-cards': InfoCards,
  'not-found': NotFound,
  'product-discover': ProductDiscover,
  'product-rating': ProductRating,
  'product-recipes': ProductRecipes,
  promotion: Promotion,
  'recipe-discover': RecipeDiscover,
  'recipe-preparation': RecipePreparation,
  recipe: Recipe,
  'slider-section': SliderSection,
  'story-preview': StoryPreview,
  'text-section': TextSection,
  'title-section': TitleSection,
  tracking: Tracking,
  'video-section': VideoSection,
};

export const getSlice = (name) => {
  return SLICE[name] || null;
};
