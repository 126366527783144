import { DEFAULT_LOCALE } from '../../config/index.js';

/**
 * @param {import('storyblok-js-client').ISbLinkURLObject} [doc]
 * @param {import('@storyblok/js').ISbStoryData} [page]
 */
export const linkResolver = (doc = {}, page) => {
  if (page && page?.full_slug) {
    doc = {
      cached_url: page?.full_slug,
      linktype: 'story',
    };
  }

  const slug = doc?.cached_url?.split('/');

  if (slug && slug.length > 0 && slug[0] === DEFAULT_LOCALE.shortCode) {
    slug.shift();
  }

  const path =
    doc.linktype && doc.linktype === 'story'
      ? slug && slug.length > 0
        ? `/${slug.join('/')}`
        : doc.cached_url
      : doc.cached_url;

  return path;
};
