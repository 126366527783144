import {
  HIDDEN_PRODUCT_TAG,
  SHOPIFY_GRAPHQL_API_ENDPOINT,
} from '@/helpers/constants';
import { isShopifyError } from '@/helpers/type-guards';
import { ensureStartsWith } from '@/helpers/utils';

import {
  addToCartMutation,
  createCartMutation,
  editCartItemsMutation,
  removeFromCartMutation,
} from './mutations/cart';
import { getCartQuery } from './queries/cart';
import {
  getCollectionProductsQuery,
  getCollectionQuery,
  getCollectionsQuery,
} from './queries/collection';
import { getPageQuery, getPagesQuery } from './queries/page';
import {
  getProductQuery,
  getProductRecommendationsQuery,
  getProductsQuery,
} from './queries/product';

const domain = process.env.SHOPIFY_STORE_DOMAIN
  ? ensureStartsWith(process.env.SHOPIFY_STORE_DOMAIN, 'https://')
  : '';
const endpoint = `${domain}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;
const key = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN;

export async function shopifyFetch({ headers, query, variables }) {
  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': key,
        ...headers,
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables }),
      }),
      next: {
        tags: ['shopify'],
      },
    });

    const body = await result.json();

    if (body.errors) {
      throw body.errors[0];
    }

    return {
      status: result.status,
      body,
    };
  } catch (error) {
    if (isShopifyError(error)) {
      throw {
        cause: error.cause?.toString() || 'unknown',
        status: error.status || 500,
        message: error.message,
        query,
      };
    }

    throw {
      error,
      query,
    };
  }
}

const removeEdgesAndNodes = (array) => {
  return array.edges.map((edge) => {
    return edge?.node;
  });
};

const reshapeCart = (cart) => {
  if (!cart.cost?.totalTaxAmount) {
    cart.cost.totalTaxAmount = {
      amount: '0.0',
      currencyCode: 'EUR',
    };
  }

  return {
    ...cart,
    lines: removeEdgesAndNodes(cart.lines),
  };
};

const reshapeCollection = (collection) => {
  if (!collection) {
    return undefined;
  }

  return {
    ...collection,
    path: `/search/${collection.handle}`,
  };
};

const reshapeCollections = (collections) => {
  const reshapedCollections = [];

  for (const collection of collections) {
    if (collection) {
      const reshapedCollection = reshapeCollection(collection);

      if (reshapedCollection) {
        reshapedCollections.push(reshapedCollection);
      }
    }
  }

  return reshapedCollections;
};

const reshapeImages = (images, productTitle) => {
  const flattened = removeEdgesAndNodes(images);

  return flattened.map((image) => {
    const filename = image.url.match(/.*\/(.*)\..*/)[1];
    return {
      ...image,
      altText: image.altText || `${productTitle} - ${filename}`,
    };
  });
};

const reshapeProduct = (product, filterHiddenProducts = true) => {
  if (
    !product ||
    (filterHiddenProducts && product.tags.includes(HIDDEN_PRODUCT_TAG))
  ) {
    return undefined;
  }

  const { images, variants, ...rest } = product;

  return {
    ...rest,
    images: reshapeImages(images, product.title),
    variants: removeEdgesAndNodes(variants),
  };
};

const reshapeProducts = (products) => {
  const reshapedProducts = [];

  for (const product of products) {
    if (product) {
      const reshapedProduct = reshapeProduct(product);

      if (reshapedProduct) {
        reshapedProducts.push(reshapedProduct);
      }
    }
  }

  return reshapedProducts;
};

export async function createCart() {
  const res = await shopifyFetch({
    query: createCartMutation,
    cache: 'no-store',
  });

  return reshapeCart(res.body.data.cartCreate.cart);
}

export async function addToCart(cartId, lines) {
  const res = await shopifyFetch({
    query: addToCartMutation,
    variables: {
      cartId,
      lines,
    },
    cache: 'no-store',
  });
  return reshapeCart(res.body.data.cartLinesAdd.cart);
}

export async function removeFromCart(cartId, lineIds) {
  const res = await shopifyFetch({
    query: removeFromCartMutation,
    variables: {
      cartId,
      lineIds,
    },
    cache: 'no-store',
  });

  return reshapeCart(res.body.data.cartLinesRemove.cart);
}

export async function updateCart(cartId, lines) {
  const res = await shopifyFetch({
    query: editCartItemsMutation,
    variables: {
      cartId,
      lines,
    },
    cache: 'no-store',
  });

  return reshapeCart(res.body.data.cartLinesUpdate.cart);
}

export async function getCart(cartId) {
  const res = await shopifyFetch({
    query: getCartQuery,
    variables: { cartId },
    cache: 'no-store',
  });

  // Old carts becomes `null` when you checkout.
  if (!res.body.data.cart) {
    return undefined;
  }

  return reshapeCart(res.body.data.cart);
}

export async function getCollection(handle) {
  const res = await shopifyFetch({
    query: getCollectionQuery,
    variables: {
      handle,
    },
  });

  return reshapeCollection(res.body.data.collection);
}

export async function getCollectionProducts({ collection, reverse, sortKey }) {
  const res = await shopifyFetch({
    query: getCollectionProductsQuery,
    variables: {
      handle: collection,
      reverse,
      sortKey: sortKey === 'CREATED_AT' ? 'CREATED' : sortKey,
    },
  });

  if (!res.body.data.collection) {
    return [];
  }

  return reshapeProducts(
    removeEdgesAndNodes(res.body.data.collection.products),
  );
}

export async function getCollections() {
  const res = await shopifyFetch({
    query: getCollectionsQuery,
  });
  const shopifyCollections = removeEdgesAndNodes(res.body?.data?.collections);
  const collections = [
    {
      handle: '',
      title: 'All',
      description: 'All products',
      seo: {
        title: 'All',
        description: 'All products',
      },
      path: '/search',
      updatedAt: new Date().toISOString(),
    },
    // Filter out the `hidden` collections.
    // Collections that start with `hidden-*` need to be hidden on the search page.
    ...reshapeCollections(shopifyCollections).filter(
      (collection) => !collection.handle.startsWith('hidden'),
    ),
  ];

  return collections;
}

export async function getPage(handle) {
  const res = await shopifyFetch({
    query: getPageQuery,
    variables: { handle },
  });

  return res.body.data.pageByHandle;
}

export async function getPages() {
  const res = await shopifyFetch({
    query: getPagesQuery,
  });

  return removeEdgesAndNodes(res.body.data.pages);
}

export async function getProduct(handle) {
  const res = await shopifyFetch({
    query: getProductQuery,
    variables: {
      handle,
    },
  });

  return reshapeProduct(res.body.data.product, false);
}

export async function getProductRecommendations(productId) {
  const res = await shopifyFetch({
    query: getProductRecommendationsQuery,
    variables: {
      productId,
    },
  });

  return reshapeProducts(res.body.data.productRecommendations);
}

export async function getProducts({ query, reverse, sortKey }) {
  const res = await shopifyFetch({
    query: getProductsQuery,
    variables: {
      query,
      reverse,
      sortKey,
    },
  });

  return reshapeProducts(removeEdgesAndNodes(res.body.data.products));
}

/**
 * shopify richText render
 * @param {Object} content
 */
export function shopifyRichTextRender(content) {
  let html = '';
  if (!content) return html;
  content.children.forEach((node) => {
    switch (node.type) {
      case 'heading':
        html += `<h${node.level}>${node.children[0].value}</h${node.level}>`;
        break;
      case 'list':
        html += `<${node.listType === 'unordered' ? 'ul' : 'ol'}>`;
        node.children.forEach((item) => {
          html += `<li>${item.children[0].value}</li>`;
        });
        html += `<${node.listType === 'unordered' ? '/ul' : '/ol'}>`;
        break;
      case 'paragraph':
        html += `<p>`;
        node.children.forEach((item) => {
          if (item.type === 'text' && item.bold) {
            html += `<strong>${item.value}</strong>` + ' ';
          } else if (item.type === 'text' && item.italic) {
            html += `<em>${item.value}</em>` + ' ';
          } else if (item.type === 'text') {
            html += `${item.value}` + ' ';
          }
          if (item.type === 'link' && item.bold) {
            html +=
              `<a href="${item.url}" target="${item.target}"><strong>${item.children[0].value}</strong></a>` +
              ' ';
          } else if (item.type === 'link' && item.italic) {
            html +=
              `<a href="${item.url}" target="${item.target}"><em>${item.children[0].value}</em></a>` +
              ' ';
          } else if (item.type === 'link') {
            html +=
              `<a href="${item.url}" target="${item.target}">${item.children[0].value}</a>` +
              ' ';
          }
        });
        html += `</p>`;
        break;
    }
  });
  return html;
}

/**
 * Replace on richtext
 * @param {Object} richtext
 * @param {Object} params
 */
export const replaceValuesInShopifyRichText = (richtext, params) => {
  return {
    ...richtext,
    children: richtext.children.map((item) => ({
      ...item,
      children: item.children.map((item2) => {
        Object.entries(params).forEach(
          ([key, value]) =>
            (item2.value = item2.value.replace(
              new RegExp(`{${key}}`, 'g'),
              value,
            )),
        );
        return item2;
      }),
    })),
  };
};
