import { storyblokEditable } from '@storyblok/js';
import classnames from 'classnames';
import React from 'react';

import HistoryLine from '@/components/history-br';
import { ICONS } from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';

const HistoryInformation = ({ blok }) => {
  const cards = blok.Icons && blok.Icons.length > 0 ? blok.Icons[0].cards : [];

  return (
    <div className="history-information" {...storyblokEditable(blok)}>
      <MaxWidth>
        <HistoryLine />
        {blok.title ? (
          <h3 className="history-information__title">{blok.title}</h3>
        ) : null}
        {blok.text_section ? (
          <div className="history-information__text">
            {render(blok.text_section)}
          </div>
        ) : null}
        <div
          className={classnames('history-information__container', {
            'history-information__container--double': cards.length === 2,
            'history-information__container--simple': cards.length === 1,
          })}
        >
          {cards.map((item, index) => (
            <div className="history-information__card" key={index}>
              <div className="history-information__card-icon">
                {item.icon ? <img src={ICONS[item.icon]} alt="" /> : null}
              </div>

              <div className="history-information__card-content">
                {item.title ? (
                  <h3 className="history-information__card-title">
                    {item.title}
                  </h3>
                ) : null}
                {item.description ? (
                  <div className="history-information__card-description">
                    {render(item.description)}
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default HistoryInformation;
