import BezierEasing from 'bezier-easing';
import classNames from 'classnames';
import { generateImageProps } from 'helpers/image';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Sticky } from 'react-sticky';

export const ProductHeroSlider = ({ images, selectedVariant }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryScrollTimeout, setGalleryScrollTimeout] = useState(null);
  const [sticky, setSticky] = useState(false);

  const galleryRef = useRef(null);
  const galleryScrollRef = useRef(null);
  const galleryItemsRef = useRef([]);
  const galleryMiniMapRef = useRef(null);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)',
  });

  const easing = BezierEasing(0.42, 0, 0.58, 1);

  const scrollMiniMap = () => {
    if (galleryMiniMapRef.current) {
      const targetScroll =
        easing(currentIndex / (images.length - 1)) *
        (galleryMiniMapRef.current.scrollWidth -
          galleryMiniMapRef.current.offsetWidth);

      galleryMiniMapRef.current.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  const goTo = (variation, { smooth = true } = {}) => {
    let newCurrent =
      currentIndex <= 0 && variation < currentIndex
        ? images.length - 1
        : currentIndex >= images.length - 1 && variation > currentIndex
        ? 0
        : variation;

    let targetScroll = newCurrent * galleryItemsRef.current[0].offsetWidth - 1;

    setCurrentIndex(newCurrent);

    if (galleryScrollTimeout) {
      clearTimeout(galleryScrollTimeout);
    }

    galleryScrollRef.current.style.scrollSnapType = 'none';

    galleryScrollRef.current.scrollTo({
      left: targetScroll,
      behavior: smooth ? 'smooth' : 'auto',
    });
  };

  const handleScroll = () => {
    if (galleryScrollRef.current.style.scrollSnapType !== 'none') {
      const newCurrent = Math.round(
        (galleryScrollRef.current.scrollLeft /
          galleryScrollRef.current.scrollWidth) *
          images.length,
      );

      setCurrentIndex(newCurrent);
    }

    if (galleryScrollTimeout) {
      clearTimeout(galleryScrollTimeout);
    }

    setGalleryScrollTimeout(
      setTimeout(() => {
        galleryScrollRef.current.style.scrollSnapType = null;
      }, 300),
    );
  };

  useEffect(() => {
    scrollMiniMap();
  }, [currentIndex]);

  useEffect(() => {
    const findImage = images.find((image) => {
      return image.filename === selectedVariant.image.url;
    });

    if (findImage) {
      goTo(images.indexOf(findImage), {
        smooth: false,
      });
    }
  }, [selectedVariant]);

  const sliderContent = (
    <div className="product-hero-slider__inner">
      <div className="product-hero-slider__square">
        <div
          className="product-hero-slider__scroll"
          ref={galleryScrollRef}
          onScroll={handleScroll}
        >
          <ul className="product-hero-slider__list">
            {images.length > 0 ? (
              <>
                {images.map((image, index) => {
                  return (
                    <li
                      key={index}
                      className="product-hero-slider__list__item"
                      ref={(el) => {
                        galleryItemsRef.current.push(el);
                      }}
                    >
                      <img
                        className="product-hero-slider__image product-hero-slider__image--cover"
                        {...generateImageProps(image, {
                          sizes: '(min-width: 769px) 200px, 15vw',
                          draggable: 'false',
                        })}
                        draggable="false"
                      />
                    </li>
                  );
                })}
              </>
            ) : (
              <li className="product-hero-slider__list__item">
                <img
                  className="product-hero-slider__image product-hero-slider__image--placeholder"
                  src="/assets/illustrations/placeholder.svg"
                  alt=""
                  draggable="false"
                />
              </li>
            )}
          </ul>
        </div>

        {images.length > 1 ? (
          <button
            className="product-hero-slider__control-button product-hero-slider__control-button--previous"
            aria-label="Image précédente"
            onClick={() => {
              goTo(currentIndex - 1, {
                smooth: true,
              });
            }}
          >
            <span>
              <img src="/icons/gallery-chevron.svg" alt="" draggable="false" />
            </span>
          </button>
        ) : null}

        {images.length > 1 ? (
          <button
            className="product-hero-slider__control-button product-hero-slider__control-button--next"
            aria-label="Image suivante"
            onClick={() => {
              goTo(currentIndex + 1, {
                smooth: true,
              });
            }}
          >
            <span>
              <img src="/icons/gallery-chevron.svg" alt="" draggable="false" />
            </span>
          </button>
        ) : null}

        {images.length > 1 ? (
          <div className="product-hero-slider__pagination">
            {images.map((image, index) => {
              return (
                <div
                  key={index}
                  className={classNames('', {
                    '--active': currentIndex === index,
                  })}
                />
              );
            })}
          </div>
        ) : null}
      </div>

      {images.length > 1 && (
        <div className="product-hero-slider__mini-map">
          <ul ref={galleryMiniMapRef}>
            {images.map((image, index) => (
              <li
                key={index}
                className={classNames('product-hero-slider__mini-map__item', {
                  'product-hero-slider__mini-map__item--active':
                    currentIndex === index,
                })}
              >
                <button
                  onClick={() => {
                    goTo(index, {
                      smooth: false,
                    });
                  }}
                  onMouseEnter={() => {
                    goTo(index, {
                      smooth: false,
                    });
                  }}
                >
                  <img
                    loading="lazy"
                    className="product-hero-slider__mini-map__image product-hero-slider__mini-map__image--cover"
                    {...generateImageProps(image, {
                      sizes: '(min-width: 769px) 200px, 15vw',
                      draggable: 'false',
                    })}
                    draggable="false"
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classNames('product-hero-slider', {
        'product-hero-slider--multiple': images.length > 1,
        'product-hero-slider--is-sticky': sticky,
      })}
      role="region"
      aria-label="Gallerie d'images"
      tabIndex="0"
      ref={galleryRef}
    >
      {isMobile ? (
        sliderContent
      ) : (
        <Sticky topOffset={-137} bottomOffset={10} disableCompensation>
          {({ style, isSticky }) => {
            setSticky(isSticky);

            const modifiedStyle = {
              ...style,
              paddingTop: isSticky ? '137px' : '0px',
            };

            return (
              <div className="product-hero-slider__inner" style={modifiedStyle}>
                {sliderContent}
              </div>
            );
          }}
        </Sticky>
      )}
    </div>
  );
};
