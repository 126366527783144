import React, { useEffect } from 'react';
import Spinner from 'react-svg-spinner';

import { triggerViewCart } from '@/helpers/gtm';
import { useCart } from '@/helpers/shopify/cart';

delete Spinner.defaultProps;

export const OpenCart = () => {
  const [cart, actions] = useCart();

  const quantity = cart.data?.totalQuantity;

  const toggleModal = () => {
    actions?.set?.('open', !cart.open);
  };

  useEffect(() => {
    if (cart.open && cart.data) {
      triggerViewCart(cart.data);
    }
  }, [cart.open]);

  return (
    <button
      className="cart__open-cart"
      aria-label="Ouvrir panier"
      onClick={toggleModal}
    >
      <img src="/icons/cart.svg" alt="" />

      {cart.loading ? (
        <span className="cart__open-cart__loading">
          <Spinner color="black" speed="fast" size="12" gap={4} thickness={4} />
        </span>
      ) : quantity ? (
        <span className="cart__open-cart__quantity">
          <span>{quantity}</span>
        </span>
      ) : null}
    </button>
  );
};
