import { useQuery } from '@apollo/client';
import { storyblokEditable } from '@storyblok/js';
import React, { useEffect, useMemo } from 'react';
import Spinner from 'react-svg-spinner';

import { MaxWidth } from '@/components/max-width';
import { ProductCard } from '@/components/product-card';

import { GET_PRODUCTS_QUERY } from '@/helpers/apollo/queries/product';
import { triggerViewItemList } from '@/helpers/gtm';

const AllProducts = ({ blok }) => {
  const { loading, error, data } = useQuery(GET_PRODUCTS_QUERY, {
    variables: {
      sortKey: 'TITLE',
      reverse: false,
    },
  });

  const { products } = data ?? {};

  const filteredProducts = useMemo(() => {
    return process.env.NODE_ENV === 'development'
      ? products?.edges
      : products?.edges?.filter((product) => {
          return !product.node.tags.includes('development');
        });
  }, [products]);

  const itemListName = blok.component;

  useEffect(() => {
    if (filteredProducts) {
      const shopifyProducts = filteredProducts.map((productEdge) => {
        return {
          ...productEdge.node,
          variants: productEdge.node.variants.edges.map((variantEdge) => {
            return variantEdge.node;
          }),
        };
      });

      triggerViewItemList({
        itemListName,
        shopifyProducts,
      });
    }
  }, [filteredProducts, itemListName]);

  if (loading) {
    return (
      <span className="all-products__loading">
        <Spinner color="black" speed="fast" size="32" gap={4} thickness={4} />
      </span>
    );
  }

  if (error) {
    return <p>Error</p>;
  }

  return (
    <div className="all-products" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="all-products__container">
          {filteredProducts.map((item) => (
            <ProductCard
              key={item.node.id}
              product={item.node}
              itemListName={itemListName}
            />
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default AllProducts;
