import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const ImageText = ({ blok }) => {
  return (
    <div className="image-text" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="image-text__container">
          <div className="image-text__content">
            <div className="image-text__content__icon" />
            <div className="image-text__content__title">
              {blok.title ? <h3>{blok.title}</h3> : null}
            </div>

            <div className="image-text__content__description">
              {blok.description ? render(blok.description) : null}
            </div>
          </div>

          <div className="image-text__image">
            <img
              {...generateImageProps(blok.image, {
                sizes: '(min-width: 769px) 600px, 50vw',
                draggable: 'false',
              })}
            />
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default ImageText;
