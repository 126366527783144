import React from 'react';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

import { Link } from '../link';
import { MaxWidth } from '../max-width';

export const FooterSocial = ({ socialProfiles }) => {
  const socialLinks = socialProfiles ? socialProfiles.split('\n') : [];

  const facebook = socialLinks.find((url) => /facebook.com/.test(url));
  const twitter = socialLinks.find((url) => /twitter.com/.test(url));
  const instagram = socialLinks.find((url) => /instagram.com/.test(url));
  const linkedin = socialLinks.find((url) => /linkedin.com/.test(url));

  return (
    <ul className="footer__social">
      {facebook && (
        <li>
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <img
              src="/icons/social/facebook.svg"
              alt="Facebook"
              draggable={false}
              width="17.78px"
              height="17.78px"
            />
          </a>
        </li>
      )}

      {instagram && (
        <li>
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <img
              src="/icons/social/instagram.svg"
              alt="Instagram"
              draggable={false}
              width="17.78px"
              height="17.78px"
            />
          </a>
        </li>
      )}

      {twitter && (
        <li>
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <img
              src="/icons/social/twitter.svg"
              alt="Twitter"
              draggable={false}
              width="17.78px"
              height="17.78px"
            />
          </a>
        </li>
      )}

      {linkedin && (
        <li>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <img
              src="/icons/social/linkedin.svg"
              alt="Linkedin"
              draggable={false}
              width="17.78px"
              height="17.78px"
            />
          </a>
        </li>
      )}
    </ul>
  );
};

export const Footer = ({ blok }) => {
  return (
    <footer className="footer">
      <MaxWidth>
        <div className="footer__content">
          {blok.columns.map((item, index) => (
            <div key={index} className="footer__column__item">
              {blok.logo && item.logo ? (
                <Link link={blok.logoLink} className="footer__infos__logo">
                  <img
                    {...generateImageProps(blok.logo, {
                      sizes: '(min-width: 769px) 600px, 25vw',
                      draggable: 'false',
                    })}
                  />
                  <span className="visually-hidden">Open menu</span>
                </Link>
              ) : null}
              {item.title ? (
                <div className="footer__column__item__title">
                  <h2>{item.title}</h2>
                </div>
              ) : null}
              {item.content ? (
                <div className="footer__column__item__content">
                  {render(item.content)}
                </div>
              ) : null}
              {blok.socialProfiles && item.socialMedia ? (
                <FooterSocial socialProfiles={blok.socialProfiles} />
              ) : null}
            </div>
          ))}
        </div>
      </MaxWidth>
    </footer>
  );
};
