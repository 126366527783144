import { useMemo } from 'react';
import slug from 'slug';

import { Link } from '@/components/link';

import { richTextToString } from '@/helpers/storyblok/rich-text';

export const formatAnchorId = (string) => {
  return typeof string === 'string' ? slug(string) : undefined;
};

const generateSummary = (body) => {
  const summary = [];

  body?.content?.forEach?.((item) => {
    if (item.type === 'heading') {
      if (item.attrs.level === 2) {
        console.log(item);

        summary.push({
          title: richTextToString(item),
          sub: [],
        });
      } else if (item.attrs.level === 3 && summary[summary.length - 1]) {
        summary[summary.length - 1].sub.push({
          title: richTextToString(item),
        });
      }
    }
  });

  return summary;
};

export const Summary = ({ body }) => {
  const summary = useMemo(() => {
    return generateSummary(body);
  }, [body]);

  return summary?.length > 0 ? (
    <div className="summary">
      <p className="summary__title">Sommaire</p>

      <ul>
        {summary.map((item) => {
          return (
            <li key={item.title}>
              <Link
                href={`#${formatAnchorId(item.title)}`}
                className="summary__heading"
              >
                {item.title}
              </Link>

              {item.sub?.length > 0 ? (
                <ul>
                  {item.sub.map((subItem) => {
                    return (
                      <li key={subItem.title}>
                        <Link
                          href={`#${formatAnchorId(subItem.title)}`}
                          className="summary__heading"
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};
