import React from 'react';

import { MaxWidth } from '@/components/max-width';

const ICONS = {
  cuillere: {
    src: '/icons/tasting/cuillere.png',
    width: 156,
    height: 135,
  },
  cadre: {
    src: '/icons/tasting/cadre.png',
    width: 133,
    height: 136,
  },
  marmite: {
    src: '/icons/tasting/marmite.png',
    width: 182,
    height: 136,
  },
};

export const Recipe = ({ title, items, pushRef = null, ...rest }) => {
  return (
    <div className="recipe" ref={(el) => pushRef?.(el)} {...rest}>
      <MaxWidth>
        {title ? (
          <div className="recipe__title">
            <h2>{title}</h2>
          </div>
        ) : null}

        {Array.isArray(items) && items.length > 0 ? (
          <div className="recipe__list">
            {items.map((item, index) => {
              const icon = ICONS[item.icon];

              return (
                <div key={index} className="recipe__item">
                  {icon ? (
                    <div className="recipe__item__image">
                      <img alt="" draggable="false" {...icon} />
                    </div>
                  ) : null}

                  {item.title ? (
                    <p className="recipe__item__title">{item.title}</p>
                  ) : null}

                  {item.content ? (
                    <div
                      className="recipe__item__content"
                      {...(item.dangerousHtmlContent
                        ? {
                            dangerouslySetInnerHTML: {
                              __html: item.dangerousHtmlContent,
                            },
                          }
                        : { children: item.content })}
                    />
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};
