import { CONFIG } from '../config/index.js';

import { changeImageUrl, isImage } from './image.js';

/**
 * Generate Product Snippet schema.org
 * @param {Object} params
 * @param {Object} params.body
 * @param {String|null} [params.title=null]
 * @param {String|null} [params.description=null]
 * @param {String|null} [params.prefix=null]
 */
export const generateProductSnippet = ({ product }) => {
  const structuredDatas = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: product.images[0]?.url,
    description: product.description,
    brand: {
      '@type': 'Brand',
      name: CONFIG.title,
    },
    offers: [
      product.variants.map((variant) => {
        return {
          '@type': 'Offer',
          priceCurrency: variant.price.currencyCode,
          price: variant.price.amount,
          availability: 'https://schema.org/InStock',
        };
      }),
    ],
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDatas),
      }}
    />
  );
};

/**
 * Find time regex in string
 * @param {String} str
 * @return {Object}
 */
const findTimeInString = (str) => {
  const regex = /(\d+)\s*([a-zA-Z]+)/;
  const match = str.match(regex);
  if (match) {
    const [, number, unit] = match;
    return number && unit ? { number: number, unit: unit } : null;
  } else {
    return null;
  }
};

/**
 * Generate Recipe Snippet schema.org
 * @param {Object} params
 * @param {Object} params.body
 * @param {String|null} [params.title=null]
 * @param {String|null} [params.description=null]
 * @param {String|null} [params.prefix=null]
 */
export const generateRecipeSnippet = ({ recipe }) => {
  const structuredDatas = {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: recipe.title,
    image: recipe.image.url || `${recipe.image.filename}/m/1000x0`,
    /* author: {
      '@type': 'Person',
      name: 'Mary Stone',
    }, */
    datePublished: recipe.datePublished.split('T')[0],
    // description: 'This coffee cake is awesome and perfect for parties.',
    // prepTime: 'PT20M',
    // keywords: 'cake for a party, coffee',
    recipeYield: '1',
    // recipeCategory: 'Dessert',
    // recipeCuisine: 'American',
    /* nutrition: {
      '@type': 'NutritionInformation',
      calories: '270 calories',
    }, */
    recipeIngredient: recipe.ingredients || [],
    recipeInstructions: recipe.preparationSteps.map((step) => ({
      '@type': 'HowToStep',
      // name: `${index + 1}`,
      text: step,
      // url: 'https://example.com/party-coffee-cake#step1',
      // image: 'https://example.com/photos/party-coffee-cake/step1.jpg',
    })),
  };

  const cookTime = findTimeInString(recipe.time);

  if (cookTime) {
    structuredDatas.cookTime = `PT${
      cookTime.number
    }${cookTime.unit[0].toUpperCase()}`;
    structuredDatas.totalTime = structuredDatas.cookTime;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDatas),
      }}
    />
  );
};

export const generateBlogPostSnippet = ({ story }) => {
  const structuredDatas = {
    '@context': 'https://schema.org/',
    '@type': 'NewsArticle',
    headline: story?.content?.title,
    image: isImage(story?.content?.image)
      ? [changeImageUrl(story?.content?.image.filename, 1200)]
      : null,
    datePublished: `${story?.content?.publishDate}Z`,
    dateModified: story?.published_at || `${story?.content?.publishDate}Z`,
    author: [
      {
        '@type': 'Person',
        name: CONFIG.name,
      },
    ],
    publisher: {
      '@type': 'Organization',
      name: CONFIG.name,
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredDatas),
      }}
    />
  );
};
