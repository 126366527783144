'use client';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';

import { getLayout } from '@/layouts/index.js';

import CookieConsent from '../components/cookie-consent';

import { SHOPIFY_GRAPHQL_API_ENDPOINT } from '@/helpers/constants';
import { DataProvider } from '@/helpers/data';
import { triggerViewItem } from '@/helpers/gtm';
import { useStoryblokBridge } from '@/helpers/hooks/storyblok';
import { CartProvider } from '@/helpers/shopify/cart';
import { ensureStartsWith } from '@/helpers/utils.js';

const domain = process.env.SHOPIFY_STORE_DOMAIN
  ? ensureStartsWith(process.env.SHOPIFY_STORE_DOMAIN, 'https://')
  : '';
const endpoint = `${domain}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;
const key = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN;

export const client = new ApolloClient({
  link: new HttpLink({
    uri: endpoint,
    headers: {
      'X-Shopify-Storefront-Access-Token': key,
      'Content-Type': 'application/json',
    },
  }),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const PageClient = ({ locale, ...rest }) => {
  const [data, setData] = useState(rest.data);
  const hasTriggeredViewItem = useRef(false);

  useStoryblokBridge({
    data,
    setData,
  });

  useEffect(() => {
    if (data.product && !hasTriggeredViewItem.current) {
      const shopifyProduct = data.product;

      triggerViewItem({
        id: shopifyProduct.id,
        title: shopifyProduct.title,
        productType: shopifyProduct.productType,
        variants: shopifyProduct.variants,
      });

      hasTriggeredViewItem.current = true;
    }
  }, [data.product]);

  const Layout = getLayout(data);

  return (
    <ApolloProvider client={client}>
      <CartProvider>
        <DataProvider
          value={{
            ...data.contentTypes,
            locale,
            story: data.story,
            layouts: data.layouts,
            product: data.product,
            products: data.products,
            contentTypes: data.contentTypes,
          }}
        >
          <Layout />
          <CookieConsent locale={locale} />
        </DataProvider>
      </CartProvider>
    </ApolloProvider>
  );
};
