import { storyblokEditable } from '@storyblok/js';

import { MaxWidth } from '@/components/max-width';

import { RecipeCard } from '../recipe-discover';

const ProductRecipes = ({ blok, contentTypes, id = '', pushRef = null }) => {
  const recipes = contentTypes?.filteredBy?.recipes?.recipes;

  return recipes.length > 0 ? (
    <div
      className="product-recipes"
      id={id}
      ref={(el) => pushRef?.(el)}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <h2>{blok.title}</h2>

        <div className="product-recipes__content">
          {recipes.map((recipe) => {
            return (
              <RecipeCard key={recipe.full_slug} blok={blok} recipe={recipe} />
            );
          })}
        </div>
      </MaxWidth>
    </div>
  ) : null;
};

export default ProductRecipes;
