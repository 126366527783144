import { useCallback, useEffect, useState } from 'react';

const getInitialVariant = (productData) => {
  const initialVariant = (
    productData?.variants?.edges || productData?.variants
  ).find((item) => {
    return true;
    // FIXME:
    // return (item.node || item).availableForSale;
  });

  return initialVariant?.node || initialVariant || null;
};

export const useVariantSelection = (productData) => {
  const [selectedVariant, setSelectedVariant] = useState(
    getInitialVariant(productData),
  );

  useEffect(() => {
    if (!selectedVariant) {
      setSelectedVariant(getInitialVariant(productData));
    }
  }, [productData, selectedVariant]);

  const handleOptionChange = useCallback(
    (event) => {
      const newVariant = (
        productData?.variants?.edges || productData?.variants
      ).find((item) => {
        return (item.node || item).selectedOptions.some((option) => {
          return option.value === event.target.value;
        });
      });

      setSelectedVariant(newVariant?.node || newVariant || null);
    },
    [productData?.variants, setSelectedVariant],
  );

  return {
    selectedVariant,
    handleOptionChange,
  };
};
