// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

import {
  augmentDimensions,
  changeImageUrl,
  DEFAULT_FOCUS_POINT,
  FOCUS_POINT,
  generateImageProps,
  generateSrcSet,
  getImageDimensions,
  isImage,
} from '@/helpers/image';

import 'photoswipe/dist/photoswipe.css';

/**
 * @param {Object} props
 * @param {Object} props.image
 * @param {boolean} [props.useZoom=false]
 * @param {string} [props.sizes='100vw']
 * @param {boolean} [props.lazy=false]
 * @param {string} [props.draggable='true']
 * @param {string} [props.focusPoint=null]
 *
 * @param {string} [props.className=undefined]
 * @param {any} [props.onClick=undefined]
 */
export const Image = forwardRef(function ImageWithRef(
  {
    image,
    sizes = '100vw',
    useZoom = false,
    lazy = false,
    draggable = 'true',
    focusPoint = null,
    className,
    ...rest
  },
  ref,
) {
  if (isImage(image)) {
    if (useZoom) {
      return (
        <ZoomableImage
          ref={ref}
          image={image}
          className={className}
          {...rest}
        />
      );
    } else {
      const objectPosition = FOCUS_POINT[focusPoint] || DEFAULT_FOCUS_POINT;

      return (
        <img
          ref={ref}
          {...generateImageProps(image, {
            sizes,
            lazy,
            draggable,
          })}
          className={className}
          style={{
            objectPosition,
          }}
          {...rest}
        />
      );
    }
  }

  return null;
});

const galleryOptions = {
  zoom: false,
  showAnimationDuration: 0,
  hideAnimationDuration: 0,
};

export const ImgDimensions = forwardRef(function ImgDimensionsWithRef(
  { handleDimensions, ...rest },
  ref,
) {
  const handleLoad = (event) => {
    if (
      typeof event.target?.naturalWidth === 'number' &&
      typeof event.target?.naturalHeight === 'number'
    ) {
      handleDimensions({
        width: event.target.naturalWidth,
        height: event.target.naturalHeight,
      });
    }
  };

  useLayoutEffect(() => {
    if (
      ref.current &&
      ref.current.complete &&
      typeof ref.current.naturalWidth === 'number' &&
      typeof ref.current.naturalHeight === 'number'
    ) {
      handleDimensions({
        width: ref.current.naturalWidth,
        height: ref.current.naturalHeight,
      });
    }
  }, []);

  return <img ref={ref} onLoad={handleLoad} {...rest} />;
});

const ZoomableImage = ({ image, sizes, lazy, draggable, ...rest }) => {
  const [dimensions, setDimensions] = useState(
    augmentDimensions(getImageDimensions(image.filename)),
  );

  const handleDimensions = useCallback(
    ({ width, height }) => {
      setDimensions(
        augmentDimensions({
          width,
          height,
        }),
      );
    },
    [setDimensions],
  );

  return (
    <Gallery options={galleryOptions}>
      <Item
        key={image.id}
        original={image.filename}
        originalSrcset={generateSrcSet(image.filename)}
        thumbnail={changeImageUrl(image.filename, 300)}
        width={dimensions?.width ?? undefined}
        height={dimensions?.height ?? undefined}
      >
        {({ ref, open }) => {
          return (
            <ImgDimensions
              ref={ref}
              {...generateImageProps(image, {
                sizes,
                lazy,
                draggable,
              })}
              onClick={open}
              handleDimensions={handleDimensions}
              {...rest}
            />
          );
        }}
      </Item>
    </Gallery>
  );
};
