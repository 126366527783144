import { getSlice } from '@/sections';
import React, { useMemo } from 'react';

import { BlogBody } from '@/sections/blog-body';
import { BlogHeader } from '@/sections/blog-header';

import { Layout } from '@/components/layout/index.js';
import RecipeHero from '@/components/recipe-hero';

import { useData } from '@/helpers/data.js';
import { generateBlogPostSnippet } from '@/helpers/snippet';
import { richTextToString } from '@/helpers/storyblok/rich-text';

export const BlogPostLayout = () => {
  const { locale, story } = useData();

  return (
    <Layout locale={locale}>
      {generateBlogPostSnippet({ story })}

      <BlogHeader story={story} />

      <BlogBody story={story} />

      {/* <RecipeHero blok={story.content} />

      {(body || []).map((item, index) => {
        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice
            key={item._uid}
            index={index}
            blok={item}
            content={story.content}
          />
        ) : null;
      })}

      {(layout?.content?.body || []).map((item, index) => {
        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice
            key={item._uid}
            index={index}
            blok={item}
            content={story.content}
            contentTypes={contentTypes}
          />
        ) : null;
      })} */}
    </Layout>
  );
};
