import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { generateImageProps } from '@/helpers/image';

const Promotion = ({ blok }) => {
  return (
    <div className="promotion" {...storyblokEditable(blok)}>
      <img
        {...generateImageProps(blok.image, {
          sizes: '(min-width: 769px) 140vw, (min-width: 1280px) 100vw, 100vw',
          draggable: 'false',
        })}
      />
    </div>
  );
};

export default Promotion;
