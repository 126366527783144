import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { RecipeCard } from '../recipe-discover';

const AllRecipes = ({ blok, contentTypes }) => {
  return (
    <div className="all-recipes" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="all-recipes__container">
          {contentTypes.recipes.map((recipe) => (
            <RecipeCard key={recipe.full_slug} recipe={recipe} blok={blok} />
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default AllRecipes;
