import { useEffect } from 'react';

import { RESOLVE_RELATIONS, STORYBLOK_VERSION } from '../storyblok';
import { fetchStory } from '../storyblok/graphql.js';
import { isInVisualEditor } from '../storyblok/is-in-visual-editor.js';

export const useStoryblokBridge = ({ data, setData }) => {
  useEffect(() => {
    if (isInVisualEditor()) {
      const updateStoryContent = (content) => {
        setData((prev) => {
          return {
            ...prev,
            story: {
              ...prev.story,
              content,
            },
          };
        });
      };

      const updateData = async () => {
        const result = await fetchStory({
          byUuids: [data.story.uuid],
          resolveRelations: RESOLVE_RELATIONS,
          version: STORYBLOK_VERSION.DRAFT,
        });

        updateStoryContent(result.content);
      };

      updateData();

      const script = document.createElement('script');

      script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
      script.type = 'text/javascript';

      script.addEventListener('load', () => {
        const storyblokInstance = new window.StoryblokBridge({
          resolveRelations: RESOLVE_RELATIONS,
        });

        storyblokInstance.on('input', (event) => {
          updateStoryContent(event.story.content);
        });

        storyblokInstance.on(['change'], async () => {
          updateData();
        });

        storyblokInstance.on(['published'], async () => {
          window.location.reload();
        });
      });

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};
