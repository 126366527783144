import classnames from 'classnames';
import React from 'react';

/**
 * @param {Object} props
 * @param {any} props.children
 * @param {string|null} [props.className=null]
 */
export const MaxWidth = ({
  width = 'normal',
  children,
  className = null,
  ...rest
}) => {
  return (
    <div
      className={classnames(
        'max-width',
        `max-width--width-${width}`,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
