import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Sticky } from 'react-sticky';
import smoothscroll from 'smoothscroll-polyfill';

import { Link } from '../link';
import { MaxWidth } from '../max-width';

export const ProductBreadCrumb = ({ links, elementsRef = [] }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [sticky, setSticky] = useState(false);
  const linksContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const distances = elementsRef.current.map((element) => {
        if (element) {
          return Math.abs(scrollPosition - element.offsetTop);
        }

        return Infinity;
      });

      const closestSectionIndex = distances.indexOf(Math.min(...distances));

      if (closestSectionIndex !== activeSection) {
        setActiveSection(closestSectionIndex);
        if (!linksContainerRef?.current) return;
        const links = linksContainerRef.current.querySelectorAll('a');
        const link = links[closestSectionIndex];

        const linksContent = linksContainerRef.current.querySelector(
          '.product-breadcrumb__content',
        );

        const offsetLeft = link.offsetLeft - linksContent.offsetLeft;

        linksContent.scrollTo({
          left: offsetLeft,
          behavior: 'smooth',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div
      className={classNames('product-breadcrumb', {
        'product-breadcrumb--is-sticky': sticky,
      })}
      ref={linksContainerRef}
    >
      <MaxWidth>
        <div className="product-breadcrumb__model" />
        <Sticky topOffset={-52}>
          {({ style, isSticky }) => {
            if (sticky !== isSticky) {
              setSticky(isSticky);
            }

            return (
              <div className="product-breadcrumb__content" style={style}>
                {links.map((link, index) => {
                  return (
                    <Link
                      key={index}
                      className={classNames(
                        'product-breadcrumb__content__link',
                        {
                          'product-breadcrumb__content__link--active':
                            index === activeSection,
                        },
                      )}
                      href={link.href}
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>
            );
          }}
        </Sticky>
      </MaxWidth>
    </div>
  );
};
