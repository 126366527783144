import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const StoryPreview = ({ blok }) => {
  return (
    <div className="story-preview" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="story-preview__container">
          <div className="story-preview__illustration">
            <div className="story-preview__image">
              <img
                {...generateImageProps(blok.image, {
                  sizes: '(min-width: 769px) 500px, 50vw',
                  draggable: 'false',
                })}
              />
            </div>

            <div className="story-preview__illustration__content">
              {blok.legend || blok.location ? (
                <>
                  <div className="story-preview__illustration__icon">
                    <img src="/flower.svg" alt="flower" />
                  </div>
                  <div className="story-preview__illustration__content__text">
                    <div className="story-preview__illustration__content__text__legend">
                      {blok.legend}
                    </div>

                    <div className="story-preview__illustration__content__text__location">
                      {blok.location}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="story-preview__content">
            <div className="story-preview__content__text">
              {blok.content ? render(blok.content) : null}
            </div>
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default StoryPreview;
