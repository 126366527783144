import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';

const TextSection = ({ blok }) => {
  return (
    <div className="text" {...storyblokEditable(blok)}>
      <MaxWidth>{blok.content ? render(blok.content) : null}</MaxWidth>
    </div>
  );
};

export default TextSection;
