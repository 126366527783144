import { CONFIG } from '@/config';

export const convertToGa4Item = ({
  shopifyProduct,
  variant = null,
  ...rest
}) => {
  const extractNumericId = (gid) => {
    return (gid || '').split('/').pop();
  };

  const productVariant =
    variant ||
    (shopifyProduct.variants && shopifyProduct.variants[0]
      ? shopifyProduct.variants[0]
      : null);

  return {
    item_id: `shopify_US_${shopifyProduct.id || ''}${
      productVariant ? `_${extractNumericId(productVariant.id)}` : ''
    }`,
    item_name: shopifyProduct.title,
    item_variant: productVariant?.title || 'N/A',
    item_brand: CONFIG.shopifyBrand,
    item_category: shopifyProduct.productType || '',
    price: productVariant?.price?.amount
      ? parseFloat(productVariant?.price?.amount)
      : 0,
    currency: productVariant?.price?.currencyCode || 'EUR',
    quantity: shopifyProduct.quantity || 1,
    ...rest,
  };
};
