import { NODE_HEADING, NODE_IMAGE } from 'storyblok-rich-text-react-renderer';

import { MaxWidth } from '@/components/max-width';
import { formatAnchorId } from '@/components/summary';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';
import { reactChildrenToString } from '@/helpers/text';

import { BlogBodyAside } from './aside';

const renderHeadingNode = (children, props) => {
  const id = formatAnchorId(reactChildrenToString(children));

  const El = `h${props.level}`;

  return <El id={id}>{children}</El>;
};

const renderImageNode = (children, image) => {
  return (
    <img
      {...generateImageProps(image, {
        sizes: '(min-width: 1025px) 600px, (min-width: 769px) 100vw, 100vw',
        draggable: 'false',
      })}
    />
  );
};

export const BlogBody = ({ story }) => {
  if (!story?.content?.content) {
    return null;
  }

  return (
    <div className="blog-body">
      <MaxWidth className="blog-body__wrap">
        <article className="blog-body__content">
          {render(story?.content?.content, {
            nodeResolvers: {
              [NODE_HEADING]: renderHeadingNode,
              [NODE_IMAGE]: renderImageNode,
            },
          })}
        </article>

        <BlogBodyAside story={story} />
      </MaxWidth>
    </div>
  );
};
