import classnames from 'classnames';
import Link from 'next/link';
import { useMemo } from 'react';

import { DEFAULT_OPTION } from '@/helpers/constants';
import { useCart } from '@/helpers/shopify/cart';
import { formatCurrency } from '@/helpers/text';
import { createUrl } from '@/helpers/utils';

import { CONFIG } from '@/config';

import { Button } from '../button';
import { findCartLineVariant, useCartActions } from '../cart/actions';
import { MaxWidth } from '../max-width';
import { EditItemQuantityButton } from './edit-item-quantity-button';

export const CartModal = () => {
  const [cart, actions] = useCart();
  const { removeItem } = useCartActions();

  const closeModal = () => {
    actions?.set?.('open', false);
  };

  const handleRemoveFromCartClick = async (line) => {
    const shopifyProduct = line.node.merchandise.product;
    const variant = findCartLineVariant(line);

    await removeItem({
      line,
      shopifyProduct,
      variant,
      quantity: line.node.quantity,
    });
  };

  const count = useMemo(() => {
    return cart.data?.lines?.edges?.reduce((acc, item) => {
      return acc + parseInt(item.node.quantity);
    }, 0);
  }, [cart]);

  return (
    <div
      className={classnames('cart__modal', {
        'cart__modal--open': cart.open,
      })}
    >
      <button
        className="cart__modal__overlay"
        aria-label="Fermer panier"
        onClick={closeModal}
      />

      <MaxWidth>
        <div className="cart__modal__wrap">
          <header className="cart__modal__header">
            <div className="cart__modal__header__top">
              <h2>Mon panier</h2>

              <button aria-label="Fermer panier" onClick={closeModal}>
                <img
                  width="18"
                  height="18"
                  src="/icons/close-cart.svg"
                  alt=""
                />
              </button>
            </div>
          </header>

          {cart.data?.lines?.edges?.length > 0 ? (
            <>
              <div className="cart__modal__list-container">
                <div className="cart__modal__list-wrap">
                  {/* <p>
                    <b>Livraison offerte</b> à partir de 50,00 € d&apos;achat
                  </p> */}

                  <ul className="cart__modal__list">
                    {cart.data.lines.edges.map((item) => {
                      const merchandiseSearchParams = {};

                      item.node.merchandise.selectedOptions.forEach(
                        ({ name, value }) => {
                          if (value !== DEFAULT_OPTION) {
                            merchandiseSearchParams[name.toLowerCase()] = value;
                          }
                        },
                      );

                      const merchandiseUrl = createUrl(
                        `/${CONFIG.productsSlug}/${item.node.merchandise.product.handle}`,
                        new URLSearchParams(merchandiseSearchParams),
                      );

                      return (
                        <li className="cart__modal__item" key={item.node.id}>
                          {/* <div>
                      <DeleteItemButton item={item} />
                    </div> */}

                          <img
                            className="cart__modal__item__image"
                            src={item.node.merchandise.image.url}
                            width={item.node.merchandise.image.width}
                            height={item.node.merchandise.image.width}
                            alt={
                              item.node.merchandise.image.altText ||
                              item.node.merchandise.product.title
                            }
                          />

                          <div className="cart__modal__item__content">
                            <div className="cart__modal__item__top">
                              <div className="cart__modal__item__title">
                                <p className="cart__modal__item__title__name">
                                  <Link
                                    href={merchandiseUrl}
                                    onClick={closeModal}
                                  >
                                    {item.node.merchandise.product.title}
                                  </Link>
                                </p>

                                {item.node.merchandise.title !==
                                DEFAULT_OPTION ? (
                                  <p className="cart__modal__item__title__variation">
                                    {item.node.merchandise.title}
                                  </p>
                                ) : null}
                              </div>
                              <button
                                aria-label="Supprimer le produit"
                                onClick={() => handleRemoveFromCartClick(item)}
                              >
                                <img
                                  width="18"
                                  height="18"
                                  src="/icons/trash.svg"
                                  alt=""
                                />
                              </button>
                            </div>

                            <div className="cart__modal__item__footer">
                              <EditItemQuantityButton
                                line={item}
                                enableInput={false}
                              />

                              <p className="cart__modal__item__footer__total">
                                {formatCurrency(
                                  item.node.cost.totalAmount.amount,
                                  {
                                    currency:
                                      item.node.cost.totalAmount.currencyCode,
                                  },
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="cart__modal__summerize">
                {/* {cart.data.cost.totalTaxAmount ? (
                  <div>
                    <p>Taxes</p>

                    {formatCurrency(cart.data.cost.totalTaxAmount.amount, {
                      currency: cart.data.cost.totalTaxAmount.currencyCode,
                    })}
                  </div>
                ) : null}

                <div>
                  <p>Frais de livraison</p>
                  <p className="text-right">Calculés au paiement</p>
                </div> */}

                <div className="cart__modal__summerize__sub-total">
                  <div className="cart__modal__summerize__sub-total__row">
                    <p className="bold">Sous-total</p>

                    <p>
                      <span className="light">
                        {count} article{count > 1 ? 's' : ''} |
                      </span>
                      <span className="bold">
                        {formatCurrency(cart.data.cost.totalAmount.amount, {
                          currency: cart.data.cost.totalAmount.currencyCode,
                        })}
                      </span>
                    </p>
                  </div>

                  <div className="cart__modal__summerize__sub-total__row">
                    <p>Frais de livraison</p>

                    {/* <p className="bold">
                      {parseInt(cart.data.cost.totalAmount.amount) < 50
                        ? '8,80 €'
                        : 'Offerts'}
                    </p> */}

                    {/* <p className="bold">8,80 €</p> */}

                    <p className="bold">Calculés au paiement</p>
                  </div>
                </div>

                <div className="cart__modal__summerize__total">
                  <p>Total</p>

                  <p>
                    {/* {formatCurrency(
                      parseInt(cart.data.cost.totalAmount.amount) +
                        (parseInt(cart.data.cost.totalAmount.amount) < 50
                          ? 8.8
                          : 0),
                      {
                        currency: cart.data.cost.totalAmount.currencyCode,
                      },
                    )} */}

                    {formatCurrency(
                      parseInt(cart.data.cost.totalAmount.amount),
                      {
                        currency: cart.data.cost.totalAmount.currencyCode,
                      },
                    )}
                  </p>
                </div>
              </div>

              <Button
                as="a"
                href={cart.data.checkoutUrl}
                width="full"
                theme="black"
              >
                Valider mon panier
              </Button>
            </>
          ) : (
            <p>Votre panier est vide</p>
          )}
        </div>
      </MaxWidth>
    </div>
  );
};
