import classnames from 'classnames';
import React from 'react';

import { Link } from '@/components/link';

import { render } from '@/helpers/storyblok/rich-text';

const TimelineDate = ({ item }) => {
  return (
    <div className="timeline-date">
      <p>{item.date}</p>
    </div>
  );
};

const TimelineItem = ({ item, reverse }) => {
  return (
    <div
      className={classnames('timeline-content', {
        'timeline-content--odd': reverse,
      })}
    >
      <img
        className="timeline-content__img"
        src={item.image.filename}
        alt={item.image.alt}
      />
      <div className="timeline-content__text">
        {render(item.text_area)}

        {item.links && item.links.length > 0 ? (
          <div className="timeline-content__text__links">
            <div className="timeline-content__text__links__title">
              <img src="icons/download.svg" alt="" />
              <h3>{item.linkTitle}</h3>
            </div>
            <div className="timeline-content__text__links__links">
              {item.links.map((link) => {
                return (
                  <Link key={link._uid} href={link.link.cached_url}>
                    {link.text}
                  </Link>
                );
              })}
            </div>
          </div>
        ) : null}
        {item.cta && item.cta.length > 0 ? (
          <div className="timeline-content__text__cta">
            {item.cta.map((cta) => {
              return (
                <>
                  <Link key={cta._uid} href={cta.link.cached_url}>
                    <span>{cta.text}</span>
                    <img src="icons/chevron.svg" alt="" />
                  </Link>
                </>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Timeline = ({ items }) => {
  return (
    <div className="timeline">
      {items.map((item, index) => {
        if (item.component === 'history-step_date') {
          return (
            <>
              <TimelineDate key={item._uid} item={item} />
            </>
          );
        }

        if (item.component === 'history-step_item') {
          const reverse =
            items.slice(0, index + 1).filter((item) => {
              return item.component === 'history-step_item';
            }).length %
              2 ===
            0;

          return <TimelineItem key={item._uid} item={item} reverse={reverse} />;
        }

        return null;
      })}
    </div>
  );
};

export default Timeline;
