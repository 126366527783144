import { gql } from '@apollo/client';

import productFragment from '@/helpers/shopify/fragments/product';

export const GET_PRODUCT_QUERY = gql`
  ${productFragment}
  query getProduct($handle: String!) {
    product(handle: $handle) {
      ...product
    }
  }
`;

export const GET_PRODUCTS_QUERY = gql`
  ${productFragment}
  query getProducts(
    $sortKey: ProductSortKeys
    $reverse: Boolean
    $query: String
  ) {
    products(sortKey: $sortKey, reverse: $reverse, query: $query, first: 100) {
      edges {
        node {
          ...product
        }
      }
    }
  }
`;

export const GET_PRODUCT_RECOMMENDATIONS_QUERY = gql`
  ${productFragment}
  query getProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      ...product
    }
  }
`;
