import { gql } from '@apollo/client';

import cartFragment from '@/helpers/shopify/fragments/cart';

export const GET_CART_QUERY = gql`
  ${cartFragment}
  query getCart($cartId: ID!) {
    cart(id: $cartId) {
      ...cart
    }
  }
`;
