import React from 'react';

import { Layout } from '@/components/layout';

import { useData } from '@/helpers/data.js';

import { getSlice } from '../sections/index.js';

export const PageLayout = () => {
  const { story, locale, contentTypes } = useData();

  const body = story.content?.body || [];

  return (
    <Layout locale={locale}>
      {(body || []).map((item, index) => {
        const Slice = getSlice(item.component);

        return Slice ? (
          <Slice
            key={item._uid}
            index={index}
            blok={item}
            contentTypes={contentTypes}
          />
        ) : null;
      })}
    </Layout>
  );
};
