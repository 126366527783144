import cookies from 'js-cookie';

import { triggerAddToCart, triggerRemoveFromCart } from '@/helpers/gtm';
import { useCartOperations } from '@/helpers/hooks/use-cart-operations';

export const findCartLineVariant = (line) => {
  return line.node.merchandise.product.variants.edges.find((item) => {
    return item.node.id === line.node.merchandise.id;
  })?.node;
};

export const useCartActions = () => {
  const { addToCart, createCart, removeFromCart, editCartItems } =
    useCartOperations();

  const addItem = async ({ shopifyProduct, variant, quantity = 1 }) => {
    const cartId = cookies.get('cartId');

    const line = {
      merchandiseId: variant.id,
      quantity,
    };

    if (!cartId) {
      const { data } = await createCart({
        variables: {
          lineItems: [line],
        },
      });

      cookies.set('cartId', data.cartCreate.cart.id);
    } else {
      await addToCart({
        variables: {
          cartId,
          lines: [line],
        },
      });
    }

    triggerAddToCart({
      shopifyProduct,
      variant,
      quantity,
    });
  };

  const removeItem = async ({ line, shopifyProduct, variant, quantity }) => {
    const cartId = cookies.get('cartId');

    if (cartId) {
      await removeFromCart({
        variables: {
          cartId: cartId,
          lineIds: [line.node.id],
        },
      });

      triggerRemoveFromCart({
        shopifyProduct,
        variant,
        quantity,
      });
    }
  };

  const updateItemQuantity = async ({ line, quantity }) => {
    const cartId = cookies.get('cartId');

    if (cartId) {
      const shopifyProduct = line.node.merchandise.product;
      const variant = findCartLineVariant(line);

      const lines = [
        {
          id: line.node.id,
          merchandiseId: variant.id,
          quantity,
        },
      ];

      await editCartItems({
        variables: {
          cartId,
          lines,
        },
      });

      const difference = quantity - line.node.quantity;

      if (difference > 0) {
        triggerAddToCart({
          shopifyProduct,
          variant,
          quantity: difference,
        });
      } else if (difference < 0) {
        triggerRemoveFromCart({
          shopifyProduct,
          variant,
          quantity: -difference,
        });
      }
    }
  };

  return { addItem, removeItem, updateItemQuantity };
};
