import React from 'react';

import { render } from '@/helpers/storyblok/rich-text';

export const Title = ({
  items = { title: null, description: null },
  params = {
    isFirst: false,
  },
}) => {
  return (
    <div className="title">
      <div className="title__container">
        <div className="title__flag">
          <img src="/flag.svg" alt="flag" />
        </div>

        {items.title ? (
          <div className="title__title">
            {params.isFirst ? <h1>{items.title}</h1> : <h2>{items.title}</h2>}
          </div>
        ) : null}

        {items.description ? (
          <div className="title__description">{render(items.description)}</div>
        ) : null}
      </div>
    </div>
  );
};
