import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

function EditQuantity({
  loading = false,
  productQuantity,
  setProductQuantity,
  enableInput = false,
}) {
  const [value, setValue] = useState(productQuantity);

  useEffect(() => {
    if (value && value !== productQuantity) {
      setProductQuantity(parseInt(value));
    }
  }, [value]);

  useEffect(() => {
    if (value !== productQuantity) {
      setValue(productQuantity);
    }
  }, [productQuantity]);

  return (
    <div
      className={classnames('edit-quantity', {
        'edit-quantity--loading': loading,
      })}
    >
      <button
        onClick={() => {
          setProductQuantity(Math.max(1, productQuantity - 1));
        }}
      >
        -
      </button>
      <div className="edit-quantity__text">
        {enableInput ? (
          <input
            type="number"
            max={99}
            min={1}
            value={value}
            onChange={(e) => {
              if (e.target.value && parseInt(e.target.value) > 99) {
                setValue(99);
              } else if (e.target.value && parseInt(e.target.value) < 1) {
                setValue(1);
              } else setValue(e.target.value);
            }}
            onBlur={(e) => {
              if (!e.target.value) setValue(productQuantity);
            }}
          />
        ) : (
          <p>{productQuantity}</p>
        )}
      </div>
      <button
        onClick={() => {
          setProductQuantity(productQuantity < 99 ? productQuantity + 1 : 99);
        }}
      >
        +
      </button>
    </div>
  );
}

export default EditQuantity;
