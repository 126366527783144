import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

import { ContactForm } from './form';

const Contact = ({ blok, index }) => {
  const isFirst = index === 0;
  return (
    <div className="contact" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="contact__container">
          <div className="contact__informations">
            <div className="contact__informations__image">
              <img
                {...generateImageProps(blok.image, {
                  sizes: '(min-width: 769px) 800px, 75vw',
                  draggable: 'false',
                })}
              />
            </div>
            <div className="contact__informations__content">
              <div className="contact__informations__content__section">
                <h3>{blok.titlePostalAddress}</h3>
                {render(blok.contentPostalAddress)}
              </div>
              <div className="contact__informations__content__section">
                <h3>{blok.titleEmail}</h3>
                <p>{render(blok.contentEmail)}</p>
              </div>
              <div className="contact__informations__content__section">
                <h3>{blok.titlePhone}</h3>
                <p>{render(blok.contentPhone)}</p>
              </div>
              <div className="contact__informations__content__section">
                <h3>{blok.titleSocial}</h3>
                {render(blok.contentSocial)}
              </div>
            </div>
          </div>
          <ContactForm blok={blok} isFirst={isFirst} />
        </div>
      </MaxWidth>
    </div>
  );
};

export default Contact;
