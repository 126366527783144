import { storyblokEditable } from '@storyblok/js';
import classNames from 'classnames';
import React from 'react';

import { Button } from '@/components/button';
import { MaxWidth } from '@/components/max-width';
import { Title } from '@/components/title';

const NotFound = ({ blok, index }) => {
  const isFirst = index === 0;
  return (
    <div
      className={classNames('not-found', {
        'not-found--first': isFirst,
      })}
      {...storyblokEditable(blok)}
    >
      <MaxWidth>
        <Title
          items={{
            supTitle: blok.supTitle,
            title: blok.title,
            description: null,
          }}
        />
        {blok.buttonText ? (
          <div className="not-found__button">
            <Button
              field={blok.buttonLink}
              theme="black"
              rightIcon="right-arrow"
            >
              {blok.buttonText}
            </Button>
          </div>
        ) : null}
      </MaxWidth>
    </div>
  );
};

export default NotFound;
