import classnames from 'classnames';
import React from 'react';

import { isLink } from '@/helpers/is-link.js';

import { RightArrow } from '../icons/index.js';
import { Link } from '../link';

export const Button = ({
  as = 'button',
  field = null,
  width = 'normal',
  height = 'normal',
  horizontalPadding = 'normal',
  theme = 'primary',
  leftIcon = null,
  rightIcon = null,
  children,
  disabled = false,

  ...rest
}) => {
  const El = field ? Link : as;

  const isDisabled = field && !isLink(field) ? true : disabled;

  return (
    <El
      className={classnames(
        'button',
        `button--width-${width}`,
        `button--height-${height}`,
        `button--horizontal-padding-${horizontalPadding}`,
        `button--theme-${theme}`,
        {
          'button--disabled': isDisabled,
        },
      )}
      link={El === Link ? field : null}
      disabled={El === 'button' ? isDisabled : null}
      {...rest}
    >
      <span className="button__background"></span>

      <span className="button__content">
        <span className="button__text">{children}</span>

        {rightIcon ? (
          <span className="button__right-icon">
            {rightIcon === 'right-arrow' ? <RightArrow /> : null}
          </span>
        ) : null}
      </span>
    </El>
  );
};
