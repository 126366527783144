'use client';

import { useMutation, useQuery } from '@apollo/client';
import cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { client } from '@/app/client';

import { createCartMutation } from '@/helpers/apollo/mutations/cart';
import { GET_CART_QUERY } from '@/helpers/apollo/queries/cart';

const initialStore = {
  open: false,
  loading: false,
  data: null,
};

const Store = createContext([{}, {}]);

export const useCart = () => {
  return useContext(Store);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(initialStore);

  const set = useCallback(
    (name, value) => {
      setCart((state) => {
        const updated = Object.assign({}, state);

        updated[name] = value;

        return updated;
      });
    },
    [setCart],
  );

  const [cartId, setCartId] = useState(null);

  const result = useQuery(GET_CART_QUERY, {
    variables: {
      cartId,
    },
    notifyOnNetworkStatusChange: true,
    skip: !cartId, // Only execute the query if a cartId is provided
  });

  useEffect(() => {
    set('loading', result.loading);
    set('networkStatus', result.networkStatus);
    set('error', result.error);
    set('data', result.data?.cart);
  }, [set, result]);

  const [createCart] = useMutation(createCartMutation);

  useEffect(() => {
    (async () => {
      const storedCartId = cookies.get('cartId');

      if (storedCartId) {
        const result = await client.query({
          query: GET_CART_QUERY,
          variables: {
            cartId: storedCartId,
          },
        });

        if (result.data && result.data.cart) {
          setCartId(storedCartId);

          return;
        }
      }

      const createCartResult = await createCart({
        variables: {
          lineItems: [],
        },
      });

      const createdCartId = createCartResult.data.cartCreate.cart.id;

      cookies.set('cartId', createdCartId);
      setCartId(createdCartId);
    })();
  }, []);

  const actions = {
    set,
  };

  return <Store.Provider value={[cart, actions]}>{children}</Store.Provider>;
};
