import { storyblokEditable } from '@storyblok/js';

import { ICONS, LongRightArrow } from '@/components/icons';
import { Link } from '@/components/link';
import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';

const RecipeDiscover = ({ blok, contentTypes }) => {
  const recipes = contentTypes?.filteredBy?.recipes?.recipes;

  return recipes.length > 0 ? (
    <div className="recipe-discover" {...storyblokEditable(blok)}>
      <MaxWidth>
        <h2>{blok.title}</h2>

        <div className="recipe-discover__content">
          {recipes.map((recipe) => {
            return (
              <RecipeCard key={recipe.full_slug} blok={blok} recipe={recipe} />
            );
          })}
        </div>
      </MaxWidth>
    </div>
  ) : null;
};

export const RecipeCard = ({ blok, recipe }) => {
  return (
    <div className="recipe-card">
      <div className="recipe-card__tag">{recipe.content.taste}</div>
      <div className="recipe-card__image">
        <img
          {...generateImageProps(recipe.content.image, {
            sizes: '(min-width: 769px) 30vw, 100vw',
            draggable: 'false',
          })}
        />
      </div>
      <div className="recipe-card__bottom">
        <h3>{recipe.content.title}</h3>
        <div className="recipe-card__bottom__content">
          <div className="recipe-card__bottom__content__left">
            <img src={ICONS.recipe} alt="" />
            <p>{recipe.content.time}</p>
          </div>
          <Link page={recipe}>
            {blok.buttonText} {<LongRightArrow />}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecipeDiscover;
