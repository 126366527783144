import React from 'react';

export const formatCurrency = (value, { currency = 'EUR' } = {}) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
  }).format(value);
};

export const reactChildrenToString = (children) => {
  if (typeof children === 'string') {
    return children;
  }

  if (typeof children === 'object') {
    return React.Children.toArray(children)
      .map((value) => {
        if (typeof value === 'string') {
          return value;
        }

        if (typeof value === 'number') {
          return String(value);
        }

        if (value?.props?.children) {
          return reactChildrenToString(value.props.children);
        }

        return '';
      })
      .join(' ')
      .trim();
  }

  return '';
};
