import { storyblokEditable } from '@storyblok/js';
import React from 'react';

import { MaxWidth } from '@/components/max-width';

import { generateImageProps } from '@/helpers/image';
import { render } from '@/helpers/storyblok/rich-text';

const SECTIONS_TYPE = {
  TEXT_SECTION: 'text-section',
  IMAGE_SECTION: 'image-section',
};

const RecipePreparation = ({ blok, content }) => {
  return (
    <div className="recipe-preparation" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div className="recipe-preparation__ingredients">
          <div className="recipe-preparation__ingredients__content">
            <h2>{blok.ingredientsTitle}</h2>

            <p className="recipe-preparation__ingredients__content__persons">
              {content.persons}
            </p>

            <div className="recipe-preparation__ingredients__items">
              {blok.ingredients.map((item) => {
                return (
                  <div
                    key={item._uid}
                    className="recipe-preparation__ingredients__item"
                  >
                    <div className="recipe-preparation__ingredients__item__text">
                      {render(item.content)}
                    </div>

                    <div className="recipe-preparation__ingredients__item__quantity">
                      <p>{item.quantity}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="recipe-preparation__preparation">
          <div className="recipe-preparation__preparation__top">
            <h2>{blok.preparationTitle}</h2>

            <div className="recipe-preparation__preparation__top__line">
              <hr />
            </div>
          </div>

          <div className="recipe-preparation__preparation__steps">
            {blok.preparationSteps.map((item) => {
              switch (item.component) {
                case SECTIONS_TYPE.TEXT_SECTION:
                  const index = blok.preparationSteps
                    .filter((step) => {
                      return step.component !== SECTIONS_TYPE.IMAGE_SECTION;
                    })
                    .indexOf(item);

                  return (
                    <div
                      key={item._uid}
                      className="recipe-preparation__preparation__step recipe-preparation__preparation__step--text"
                    >
                      <p className="recipe-preparation__preparation__step--text__content__step-index">
                        {index + 1}.
                      </p>

                      <div className="recipe-preparation__preparation__step--text__content">
                        {render(item.content)}
                      </div>
                    </div>
                  );

                case SECTIONS_TYPE.IMAGE_SECTION:
                  return (
                    <div
                      key={item._uid}
                      className="recipe-preparation__preparation__step recipe-preparation__preparation__step--image"
                    >
                      <img
                        {...generateImageProps(item.image, {
                          sizes: '(min-width: 769px) 600px, 100vw',
                          draggable: 'false',
                        })}
                      />
                    </div>
                  );
              }
            })}
          </div>
        </div>
      </MaxWidth>
    </div>
  );
};

export default RecipePreparation;
