import { CONTENT_TYPE } from './content-type.js';

/**
 * @typedef {import('./content-type.js').ContentType} ContentType
 * @typedef {import('./content-type.js').ContentTypeFilter} ContentTypeFilter
 */

/**
 * @typedef {Object} SliceContentType
 * @property {ContentType} contentType
 * @property {number} [count]
 * @property {ContentTypeFilter} [filter]
 */

/**
 * @type {Object<string, SliceContentType[]>}
 */
export const SLICE_FETCH = {
  'recipe-discover': [
    {
      contentType: CONTENT_TYPE.RECIPE,
      count: 3,
      filter: {
        key: 'recipes',
        searchParamName: 'recipe',
        queryBuilderV2: (data) => {
          return {
            title: {
              not_in: data?.story?.content?.title,
            },
          };
        },
      },
    },
  ],
  'product-recipes': [
    {
      contentType: CONTENT_TYPE.RECIPE,
      count: 3,
      filter: {
        key: 'recipes',
        searchParamName: 'recipe',
        queryBuilder: (data) => {
          return { handles: { in_array: data?.product?.handle } };
        },
      },
    },
  ],
  'all-recipes': [
    {
      contentType: CONTENT_TYPE.RECIPE,
      count: Infinity,
    },
  ],
  'blog-index': [
    {
      contentType: CONTENT_TYPE.BLOG_POST,
      count: Infinity,
    },
  ],
};
