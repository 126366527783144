/**
 * @typedef {Object} ContentTypeFilter
 * @property {string} key
 * @property {string} searchParamName
 * @property {Function} queryBuilder
 */

/**
 * @typedef {Object} ContentType
 * @property {string} key
 * @property {string} component
 * @property {string} requestName
 * @property {string} [fields]
 * @property {number} [limit]
 * @property {ContentTypeFilter} [filter]
 * @property {Object<string, number>} [limitFilteredBy]
 */

/**
 * @enum {ContentType}
 * @type {Object.<string, ContentType>}
 */
export const CONTENT_TYPE = {
  PAGE: {
    key: 'pages',
    component: 'page',
    requestName: 'PageItems',
  },

  PRODUCT: {
    key: 'products',
    component: 'product',
  },

  RECIPE: {
    key: 'recipes',
    component: 'recipe',
    requestName: 'RecipeItems',
    fields: `
      title
      time
      taste
      image {
        filename
        alt
      }
    `,
  },

  BLOG_POST: {
    key: 'blogPosts',
    component: 'BlogPost',
    requestName: 'BlogpostItems',
    fields: `
      publishDate
      image {
        id
        filename
        alt
      }
      title
      introduction
    `,
  },
};
