import { storyblokEditable } from '@storyblok/js';
import classnames from 'classnames';
import React from 'react';

import { ICONS } from '@/components/icons';
import { MaxWidth } from '@/components/max-width';

import { render } from '@/helpers/storyblok/rich-text';

const InfosCards = ({ blok }) => {
  return (
    <div className="infos-cards" {...storyblokEditable(blok)}>
      <MaxWidth>
        <div
          className={classnames('infos-cards__container', {
            'infos-cards__container--double': blok.cards.length === 2,
            'infos-cards__container--simple': blok.cards.length === 1,
          })}
        >
          {blok.cards.map((item, index) => (
            <div className="infos-cards__card" key={index}>
              <div className="infos-cards__card__icon">
                {item.icon ? <img src={ICONS[item.icon]} alt="" /> : null}
              </div>

              <div className="infos-cards__card__content">
                <div className="infos-cards__card__content__title">
                  {item.title ? <h3>{item.title}</h3> : null}
                </div>

                <div className="infos-cards__card__content__description">
                  {item.description ? render(item.description) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </MaxWidth>
    </div>
  );
};

export default InfosCards;
